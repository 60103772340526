import React, { useContext, useEffect, useState } from 'react';
import useCollectionSnapshot from '../../hooks/useCollectionSnapshot';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import SubscriptionCard from '../../components/subscriptionCard';
import { loadStripe } from '@stripe/stripe-js';
import { AuthContext } from '../../hooks/AuthContext';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

export default function Subscriptions({ manageBilling }) {
    const discount = 0.2;
    const plans = useCollectionSnapshot('pricing_details');
    const [discounted, setDiscounted] = useState(true);
    const [error, setError] = useState(false);
    const auth = useContext(AuthContext);
    const [subs, setSubs] = useState([]);

    const startSub = async (priceId) => {
        // console.log('start sub if, ', auth.account.subscription);
        if (auth.account.subscription.id) {
            return manageBilling();
        }
        try {
            const res = await window.fetch(
                `${process.env.REACT_APP_FUNCTIONS_URL}/createSubscription`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        priceId,
                        customer:
                            auth.account.customer ||
                            auth.account.subscription.customer,
                        email: auth.user.email,
                        domain: window.location.origin
                    })
                }
            );
            const session = await res.json();
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({
                sessionId: session.id
            });

            if (error) {
                setError(error.message);
            }
        } catch (e) {
            console.error(e);
            setError(e);
        }
    };

    useEffect(() => {
        if (plans) {
            setSubs(plans);
        }
    }, [plans]);

    return (
        <div id='billing-subscriptions'>
            {error && (
                <div className='alert-danger'>
                    <strong>Oh No! </strong>
                    {error}
                </div>
            )}
            <br />
            <div className='switch-container'>
                <div>For 6 Months</div>
                <Toggle
                    defaultChecked
                    onChange={(e) => setDiscounted(e.target.checked)}
                />
                <div>20% off with a Year</div>
            </div>
            {subs.length > 0 && (
                <div className='plans'>
                    {/* <SubscriptionCard
                        plan={plans.find((item) => item.id === 'free')}
                        selectSub={startSub}
                    /> */}
                    <SubscriptionCard
                        plan={plans.find((item) => item.id === 'small')}
                        discount={discounted ? discount : 0}
                        selectSub={startSub}
                    />
                    <SubscriptionCard
                        plan={plans.find((item) => item.id === 'medium')}
                        discount={discounted ? discount : 0}
                        selectSub={startSub}
                    />
                    <SubscriptionCard
                        plan={plans.find((item) => item.id === 'large')}
                        discount={discounted ? discount : 0}
                        selectSub={startSub}
                    />
                    <SubscriptionCard
                        plan={plans.find((item) => item.id === 'mega')}
                        discount={discounted ? discount : 0}
                        selectSub={startSub}
                    />
                </div>
            )}
        </div>
    );
}
