import format from 'date-fns/format';

const createSeatString = (obj, includeSection) => {
    if (!obj.seats) {
        return obj.requestedSeats ? obj.requestedSeats : '';
    }
    obj.seats.sort(function (a, b) {
        if (a.section > b.section) return 1;
        if (a.section < b.section) return -1;

        if (a.row > b.row) return 1;
        if (a.row < b.row) return -1;

        if (a.view.number > b.view.number) return 1;
        if (a.view.number < b.view.number) return -1;
        return 1;
    });
    const newSeats = obj.seats.map(
        (seat) =>
            `${includeSection ? seat.view.section + '-' : ''}${seat.view.row}-${
                seat.view.number
            }`
    );

    const joined = newSeats.join(', ');
    return '"' + joined + '"';
};

const exportToCSV = (array, fileName, includeSection) => {
    const headerRow =
        'Name,First Name, Last Name,Reserved Seats,Number of Seats,Email,Phone,Reservation Date,Checked In, Checked In Date\n';

    const csv = array.map((obj) => {
        let row = `${obj.guest.name},`;
        row += `${obj.guest.name.split(' ')[0]},`;
        row += `${obj.guest.name.split(' ')[1] || ''},`;
        row += `${createSeatString(obj, includeSection)},`;
        row += `${
            obj.seats && obj.seats.length > 0
                ? parseInt(obj.seats.length)
                : parseInt(obj.requestedSeats)
        },`;
        row += `${obj.guest.email},`;
        row += `${obj.guest.phone},`;
        row += `${format(obj.createdDate, 'MM/dd/yyyy')},`;
        row += `${obj.checkedIn ? 'Yes' : 'No'},`;
        row += `${
            obj.checkedInAt
                ? format(new Date(obj.checkedInAt), 'MM/dd/yyyy p')
                : ''
        },`;
        return row;
    });

    const csvWithHeaders = headerRow + csv.join('\n');
    const csvData = new window.Blob([csvWithHeaders], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvData);

    const hiddenElement = document.createElement('a');
    hiddenElement.href = csvUrl;
    hiddenElement.target = '_blank';
    hiddenElement.download = `${fileName}.csv`;
    hiddenElement.click();
};

export default exportToCSV;
