import React, { useState, useContext, useEffect } from 'react';
import whitelogo from '../../img/white-logo-horz.png';
import blueLogo from '../../img/blue-logo-horz.png';
import { Link, Redirect, useLocation } from 'react-router-dom';
import ChurchForm from './createForms/churchForm';
import CreateUserForm from './createForms/createUserForm';
import app, { writeDoc } from '../../base';
import { AuthContext } from '../../hooks/AuthContext';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';

const Create = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [churchInfo, setChurchInfo] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [urlAccountId, setUrlAccountId] = useState(false);

    const auth = useContext(AuthContext);
    const location = useLocation();

    const steps = {
        1: (
            <ChurchForm
                storeChurch={(church) => navToNextStep(church)}
                church={churchInfo}
            />
        ),
        2: (
            <CreateUserForm
                creatingAdmin={urlAccountId !== false}
                churchId={churchInfo.url}
                finalizeCreation={(fbUser) => finishSetup(fbUser)}
                goBack={() => goBack()}
            />
        )
    };

    useEffect(() => {
        if (auth.loggedIn === true) {
            setSubmitted(true);
        }

        const existingChurch = window.localStorage.getItem('church');
        if (existingChurch) {
            setChurchInfo(JSON.parse(existingChurch));
        }

        const urlParam = new URLSearchParams(location.search).get('accountId');
        if (urlParam) {
            setUrlAccountId(urlParam);
            setChurchInfo({ url: urlParam });
            setCurrentStep((c) => c + 1);
        }
    }, [auth.loggedIn, location.search]);

    const navToNextStep = (church) => {
        setChurchInfo(church);
        window.localStorage.setItem('church', JSON.stringify(churchInfo));
        setCurrentStep(currentStep + 1);
    };

    const sendWelcomeEmail = (churchName, userEmail, userName) => {
        window.fetch(
            `${process.env.REACT_APP_FUNCTIONS_URL}/sendWelcomeEmail`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    churchName: churchName,
                    adminEmail: userEmail,
                    adminName: userName
                })
            }
        );
        window.fetch(
            `${process.env.REACT_APP_FUNCTIONS_URL}/sendCreateMapEmail`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    churchName: churchName,
                    adminEmail: userEmail
                })
            }
        );
    };

    const finishSetup = async (fbUser) => {
        window.localStorage.removeItem('church');

        if (urlAccountId !== false) {
            sendWelcomeEmail(churchInfo.name, fbUser.email, fbUser.displayName);
            return setSubmitted(true);
        }

        try {
            const skipSample = new URLSearchParams(location.search).get(
                'skipSample'
            );
            if (skipSample) {
                console.log('skipping sample setup');
                setSubmitted(true);
                return;
            }

            console.log(churchInfo.name, fbUser.email, fbUser.displayName);
            sendWelcomeEmail(churchInfo.name, fbUser.email, fbUser.displayName);

            const sampleDocs = await app
                .firestore()
                .collection('sampleSetup')
                .doc('SampleDocs')
                .get();
            const { map, service, reservations } = sampleDocs.data();

            const futureDate = addDays(new Date(), 7).getTime();
            const serviceObj = {
                ...service,
                churchId: churchInfo.url,
                date: futureDate,
                dateStr: {
                    date: format(new Date(futureDate), 'MMM do, y'),
                    time: format(new Date(futureDate), 'h:mmaaaa')
                },
                map
            };
            // console.log(serviceObj);
            const createdService = await writeDoc(
                'services',
                null,
                serviceObj,
                false
            );

            const res1 = {
                ...reservations[0],
                churchId: churchInfo.url,
                serviceId: createdService.id
            };

            await writeDoc('reservations', null, res1, false);
        } catch (error) {
            // ** silently fail here
            console.log(error);
        }

        setSubmitted(true);
    };

    const goBack = () => {
        setCurrentStep(currentStep - 1);
    };

    if (submitted === true) {
        return <Redirect to='/admin/services' />;
    }
    return (
        <div className='login-create create-account'>
            <div className='third blue-back has-brand'>
                <a
                    className='brand hidden-mobile'
                    href='https://churchreserve.com'
                >
                    <img src={whitelogo} alt='white churchreserve logo' />
                </a>
                <div className='body vert-half-top'>
                    <h3>Welcome Back</h3>
                    <p>Stay up to date with your guest’s seating request.</p>
                    <Link className='btn big' to='/login'>
                        Sign In
                    </Link>
                </div>
            </div>
            <div className='twoThird'>
                <a
                    className='brand hidden-desktop'
                    href='https://churchreserve.com'
                >
                    <img src={blueLogo} alt='blue churchreserve logo' />
                </a>
                <h3>Join ChurchReserve</h3>
                {auth.loading === false &&
                    auth.loggedIn === false &&
                    auth.user !== false &&
                    urlAccountId === false && (
                        <p className='noAccountText'>
                            Looks like you don't have a church associated with
                            your account. Create one here and you can start
                            using ChurchReserve in no time!
                        </p>
                    )}
                {urlAccountId === false && (
                    <nav className='signup-nav'>
                        <div className={currentStep === 1 ? 'active' : ''} />
                        <div className={currentStep === 2 ? 'active' : ''} />
                    </nav>
                )}
                <div className='forms'>
                    <div className='card'>{steps[currentStep]}</div>
                </div>
            </div>
        </div>
    );
};

export default Create;
