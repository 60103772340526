import React, { useState, useEffect, useContext } from 'react';
import ReservationNav from '../../components/reservationsNav';
import { TabContext } from '../../hooks/TabContext';
import ReservationsList from '../../components/reservationsList';
import ServiceMap from './ServiceMap';
import { ShrinkSidebarContext } from '../../hooks/SidebarContext';
import { writeDoc, deleteDoc } from '../../base';
import { ServiceContext } from './Service';

export default function ServiceReservations({ reservations, tabLists }) {
    const [selectedSeats, setSelectedSeats] = useState([]);

    // when I mount, collapse the sidebar
    const [shrinkMenu, setShrinkMenu] = useContext(ShrinkSidebarContext);
    const { map } = useContext(ServiceContext);

    useEffect(() => {
        console.log(shrinkMenu);
        shrinkMenu || setShrinkMenu(true);
        // console.log(reservations);
    }, []);

    const resSelected = (seats) => {
        setSelectedSeats(seats);
    };

    const updateRes = (doc, options = {}, resListType) => {
        if (options.delete) {
            deleteDoc('reservations', doc.id);
            setSelectedSeats([]);
        } else {
            const newDoc = {
                ...doc,
                status: options.status || doc.status,
                seats: selectedSeats,
                needsUpdateNotif: (resListType !== 'requests' && true) || false
            };
            writeDoc('reservations', newDoc.id, newDoc);
            setSelectedSeats([]);
        }
    };

    return (
        <div className='service-reservations grid-wrapper row'>
            <div className='grid row w-25'>
                <div className='scrolly'>
                    <ReservationNav>
                        <TabContext.Consumer>
                            {(tab) => {
                                let listType;
                                switch (tab) {
                                    case 1:
                                        listType = 'requests';
                                        break;
                                    case 3:
                                        listType = 'completed';
                                        break;
                                    default:
                                        break;
                                }
                                return (
                                    <ReservationsList
                                        reservations={tabLists[listType]}
                                        resSelected={(seats) =>
                                            resSelected(seats)
                                        }
                                        type={listType}
                                        updateRes={(
                                            doc,
                                            options = {},
                                            resListType
                                        ) =>
                                            updateRes(doc, options, resListType)
                                        }
                                        selectedSeats={selectedSeats}
                                        showSection={map && map.includeSection}
                                    />
                                );
                            }}
                        </TabContext.Consumer>
                    </ReservationNav>
                </div>
            </div>
            <ServiceMap
                reservations={reservations}
                resSelected={(seats) => {
                    resSelected(seats);
                }}
                selections={selectedSeats}
                mapUrl={map && map.url}
            />
        </div>
    );
}
