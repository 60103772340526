import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import CreateServiceForm from '../../forms/createServiceForm';

export default function CreateService() {
    const [serviceId, setServiceId] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [service, setService] = useState(false);

    useEffect(() => {
        // use session storage to get the clone service (no query, really fast);
        const original = window.sessionStorage.getItem('cloneService');
        setService(JSON.parse(original) || {});
        window.sessionStorage.removeItem('cloneService');
    }, []);

    useEffect(() => {
        if (service) {
            setLoading(false);
        }
    }, [service]);

    const navigate = (id) => {
        setError(false);
        setServiceId(false);
        setServiceId(id);
    };

    if (serviceId) {
        return <Redirect to={`/admin/service/${serviceId}/edit-seating`} />;
    }

    return (
        <div className='page-container create'>
            <h3 className='text-main'>Create a new service</h3>
            <div className='details grid w-50'>
                {error && (
                    <div className='alert-danger'>
                        <strong>Oh no!</strong> {error}
                    </div>
                )}
                <div className='card margin-bottom'>
                    {!loading && (
                        <CreateServiceForm
                            serviceObj={service}
                            complete={(id) => navigate(id)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
