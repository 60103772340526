import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Load from '../../../components/interiorLoader';
import app, { writeDoc } from '../../../base';

const ChurchForm = ({ storeChurch, church = {} }) => {
    const [validationError, setValidateError] = useState(null);
    const [error, setError] = useState(false);
    const [showLoad, setShowLoad] = useState(false);

    const validateChurch = async (newChurch) => {
        if (church.name) {
            return storeChurch(church);
        }

        setShowLoad(true);
        const regex = new RegExp(/[!@#$%^&*(),.?":{}|<>]/);

        if (regex.test(newChurch.urlId.trim()) === true) {
            console.log('failed');
            setShowLoad(false);
            return setValidateError(
                'Your church URL must be URL safe and not include any special characters or symbols.'
            );
        }
        setValidateError(null);

        try {
            const existing = await app
                .firestore()
                .collection('churches')
                .doc(newChurch.urlId)
                .get();

            if (existing.exists) {
                setShowLoad(false);
                return setValidateError(
                    "The URL you've choosen has already been taken by another church. Please choose a different one."
                );
            }

            const sampleDocs = await app
                .firestore()
                .collection('sampleSetup')
                .doc('SampleDocs')
                .get();
            const { map } = sampleDocs.data();
            await writeDoc(
                'churches',
                newChurch.urlId,
                {
                    admins: [],
                    email: newChurch.churchEmail.trim(),
                    url: newChurch.urlId,
                    name: newChurch.churchName.trim(),
                    maps: [map],
                    trial: true,
                    createdDate: new Date().toUTCString(),
                    subscription: {
                        bracket: 'free',
                        current: true
                    }
                },
                false
            );

            storeChurch({
                name: newChurch.churchName.trim(),
                email: newChurch.churchEmail.trim(),
                url: newChurch.urlId,
                id: newChurch.urlId
            });
        } catch (err) {
            console.log(err);
            setError(err.message);
        }
    };

    const formik = useFormik({
        initialValues: {
            churchName: church.name || '',
            urlId: church.url || '',
            churchEmail: church.email || ''
        },
        validationSchema: Yup.object({
            churchName: Yup.string().required('Please enter a church name'),
            urlId: Yup.string().required(
                'Please enter a url so your guests can access your services'
            ),
            churchEmail: Yup.string()
                .email('Please enter a valid email for your church')
                .required('Please enter a valid email for your church')
        }),
        onSubmit: (values) => {
            validateChurch(values);
        },
        enableReinitialize: true
    });

    const handleChange = (e) => {
        formik.setFieldValue(
            'urlId',
            e.target.value.toLowerCase().replace(' ', '')
        );
    };

    return (
        <form className='form' onSubmit={formik.handleSubmit}>
            <div className='header'>
                <h4>Get Started</h4>
            </div>
            {error !== false && (
                <div className='alert-danger'>
                    <strong>Oh no!</strong> {error}
                </div>
            )}
            <div className='form-section'>
                <label htmlFor='churchName'>Your Church's Name</label>
                <input
                    id='churchName'
                    name='churchName'
                    type='string'
                    className='form-control'
                    onChange={formik.handleChange}
                    value={formik.values.churchName}
                    placeholder=''
                />
                {formik.touched.churchName && formik.errors.churchName ? (
                    <div className='field-error'>
                        {formik.errors.churchName}
                    </div>
                ) : null}
            </div>
            <div className='form-section'>
                <label htmlFor='urlId'>Create a url for your church</label>
                <div className='url-prepend'>.churchreserve.com</div>
                <input
                    id='urlId'
                    name='urlId'
                    type='string'
                    className='form-control'
                    onChange={handleChange}
                    value={formik.values.urlId}
                />
                {formik.touched.name && formik.errors.name ? (
                    <div className='field-error'>{formik.errors.name}</div>
                ) : null}
                {validationError && (
                    <div className='field-error'>{validationError}</div>
                )}
            </div>
            <div className='form-section'>
                <label htmlFor='churchEmail'>
                    Church Support Email Address
                </label>
                <input
                    id='churchEmail'
                    name='churchEmail'
                    type='string'
                    className='form-control'
                    onChange={formik.handleChange}
                    value={formik.values.churchEmail}
                    placeholder=''
                />
                <div className='help-text'>
                    If you don't have church email address, use your personal
                </div>
                {formik.touched.churchEmail && formik.errors.churchEmail ? (
                    <div className='field-error'>
                        {formik.errors.churchEmail}
                    </div>
                ) : null}
            </div>
            <button type='submit' className='btn accent' disabled={showLoad}>
                <Load loading={showLoad}>Continue to Your Information</Load>
            </button>
        </form>
    );
};

export default ChurchForm;
