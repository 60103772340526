import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ServiceContext } from '../screens/Service/Service';

import { faBan, faUserPlus } from '@fortawesome/free-solid-svg-icons';

export default function ReservationActionsList({
    selectSeats,
    selectedType,
    completeSelection
}) {
    const [selected, setSelected] = useState('');
    const [toggleClass, setToggleClass] = useState();
    const context = useContext(ServiceContext);
    const reservations =
        context.reservations &&
        context.reservations.filter((r) => r.status === 'blockout');

    useEffect(() => {
        if (selected) {
            selectedType(selected);
            setToggleClass('slide-out');

            if (selected === 'blockout') {
                const seats = reservations.reduce(
                    (seats, reservation) => [...seats, ...reservation.seats],
                    []
                );
                selectSeats(seats);
            } else {
                selectSeats([]);
            }
        }
    }, [selected]);

    const performAction = (bool) => {
        if (bool === true) {
            completeSelection();
            setSelected('');
            setToggleClass('slide-in');
        } else {
            selectSeats([]);
            setToggleClass('slide-in');
            setSelected('');
        }
    };

    return (
        <div className='reservations-actions-list'>
            <h5>Seating Actions</h5>
            <div className='actions'>
                <div
                    className={`action-section ${
                        selected === 'blockout' ? toggleClass : ''
                    }`}
                >
                    <div
                        className='action-item front'
                        onClick={() => setSelected('blockout')}
                    >
                        <div className='grid-wrapper row'>
                            <div className='icon'>
                                <FontAwesomeIcon icon={faBan} size='lg' />
                            </div>
                            <div className='text'>Blockout</div>
                        </div>
                    </div>
                    <div className='action-item back'>
                        <div className='grid-wrapper row'>
                            <button
                                className='btn primary'
                                onClick={() => performAction(true)}
                            >
                                Save Blockouts
                            </button>
                            <button
                                className='btn outline'
                                onClick={() => performAction(false)}
                            >
                                Cancel Action
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className={`action-section ${
                        selected === 'reserve' ? toggleClass : ''
                    }`}
                >
                    <div
                        className='action-item front'
                        onClick={() => setSelected('reserve')}
                    >
                        <div className='grid-wrapper row'>
                            <div className='icon'>
                                <FontAwesomeIcon icon={faUserPlus} size='lg' />
                            </div>
                            <div className='text'>
                                Select seats for a reservation
                            </div>
                        </div>
                    </div>
                    <div className='action-item back'>
                        <div className='grid-wrapper row'>
                            <button
                                className='btn primary'
                                onClick={() => performAction(true)}
                            >
                                Create Reservation
                            </button>
                            <button
                                className='btn outline'
                                onClick={() => performAction(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
