import React, { useContext, useEffect, useState } from 'react';
import SubNav from '../../components/subNav';
import Scheduled from './ScheduledServices';
import Past from './PastServices';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import useCollectionSnapshot from '../../hooks/useCollectionSnapshot';
import { AuthContext } from '../../hooks/AuthContext';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

export default function Services() {
    const match = useRouteMatch();
    const auth = useContext(AuthContext);
    const [servicesObj, setServicesObj] = useState(false);

    const navItems = [
        { name: 'Past', route: 'past' },
        { name: 'Scheduled', route: 'scheduled' }
    ];

    const services = useCollectionSnapshot('services', {
        field: 'churchId',
        value: auth.account.id
    });

    useEffect(() => {
        const today = new Date();
        services.sort((a, b) => a.date - b.date);
        setServicesObj({
            scheduled: services.filter((service) =>
                isAfter(new Date(service.date), today)
            ),
            past: services.filter((service) =>
                isBefore(new Date(service.date), today)
            )
        });
    }, [services]);

    return (
        <>
            <SubNav navItems={navItems} />
            <div className='page-container services'>
                <Switch>
                    <Route exact path={`${match.url}`}>
                        <Redirect to={`${match.url}/scheduled`} />
                    </Route>
                    <Route path={`${match.url}/scheduled`}>
                        {servicesObj && (
                            <Scheduled services={servicesObj.scheduled} />
                        )}
                    </Route>
                    <Route path={`${match.url}/past`}>
                        {servicesObj && <Past services={servicesObj.past} />}
                    </Route>
                </Switch>
            </div>
        </>
    );
}
