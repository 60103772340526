import React from 'react';
import SubNav from '../../components/subNav';
import ChurchDetails from './ChurchDetails';
import ChurchAdmins from './ChurchAdmins';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

export default function Church() {
    const match = useRouteMatch();
    const navItems = [
        { name: 'Details', route: 'details' },
        { name: 'Admins', route: 'admins' }
    ];

    return (
        <>
            <SubNav navItems={navItems} showSignOut />
            <div className='page-container church'>
                <Switch>
                    <Route exact path={`${match.url}/`}>
                        <Redirect to={`${match.url}/details`} />
                    </Route>
                    <Route path={`${match.url}/details`}>
                        <ChurchDetails />
                    </Route>
                    <Route path={`${match.url}/admins`}>
                        <ChurchAdmins />
                    </Route>
                </Switch>
            </div>
        </>
    );
}
