import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Load from '../components/interiorLoader';
import { writeDoc } from '../base';
import NumberFormat from 'react-number-format';
import { AuthContext } from '../hooks/AuthContext';
import { ServiceContext } from '../screens/Service/Service';

export default function MakeGuestReservation({
    complete,
    selectedSeats,
    serviceId
}) {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [noSeatError, setNoSeatserror] = useState(false);
    const auth = useContext(AuthContext);
    const service = useContext(ServiceContext);

    useEffect(() => {
        if (selectedSeats.length === 0) {
            setNoSeatserror(true);
        }
    }, [selectedSeats]);

    const collisionDetection = () => {
        for (const res of service.reservations) {
            for (const seat of res.seats) {
                const matching = selectedSeats.filter((ss) => {
                    return (
                        ss.level === seat.level &&
                        ss.section === seat.section &&
                        ss.row === seat.row &&
                        ss.seat === seat.seat
                    );
                });

                if (matching.length > 0) {
                    return 'detected';
                }
            }
        }
    };

    const submitForm = async (form) => {
        setLoading(true);
        console.log(form);
        console.log(selectedSeats);

        if (collisionDetection() === 'detected') {
            complete('collision');
            return;
        }

        /***
         * Not creating a user for admin initiated reservations ***/
        const res = {
            seats: selectedSeats,
            serviceId: serviceId,
            churchId: auth.account.id,
            createdDate: new Date().getTime(),
            status: 'reserved',
            guest: {
                name: form.name,
                email: form.email,
                phone: form.phone || ''
            }
        };

        try {
            await writeDoc('reservations', null, res);
        } catch (err) {
            console.log(err);
            setError(err.message);
            setLoading(false);
            return;
        }

        complete('close');
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            email: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required(
                'You must enter a name to make a reservation.'
            ),
            email: Yup.string().required('A valid email address is required'),
            phone: Yup.string().required('A valid phone number is required')
        }),
        onSubmit: (values) => {
            submitForm(values);
        },
        enableReinitialize: false
    });

    return (
        <div className='service-form'>
            <form onSubmit={formik.handleSubmit} className='form'>
                <section>
                    <div className='form-section'>
                        <label htmlFor='name'>Guest's Name</label>
                        <input
                            id='name'
                            name='name'
                            type='string'
                            className='form-control'
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div className='field-error'>
                                {formik.errors.name}
                            </div>
                        ) : null}
                    </div>
                    <div className='form-section'>
                        <label htmlFor='email'>Guest's Email</label>
                        <input
                            id='email'
                            name='email'
                            type='email'
                            className='form-control'
                            onChange={formik.handleChange}
                            value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className='field-error'>
                                {formik.errors.email}
                            </div>
                        ) : null}
                    </div>
                    <div className='form-section'>
                        <label htmlFor='phone'>Phone Number</label>
                        <NumberFormat
                            format='(###) ###-####'
                            id='phone'
                            name='phone'
                            type='text'
                            className='form-control'
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                            <div className='field-error'>
                                {formik.errors.phone}
                            </div>
                        ) : null}
                    </div>
                    {noSeatError && (
                        <div className='alert-danger'>
                            You haven't selected any seats for this reservation.
                            You'll have to select seats before creating the
                            reservation.
                        </div>
                    )}
                    <div className='form-section'>
                        <button
                            type='submit'
                            className='btn primary outline'
                            disabled={
                                !(
                                    formik.isValid &&
                                    formik.dirty &&
                                    selectedSeats.length > 0
                                )
                            }
                        >
                            <Load loading={loading}>Create Reservation</Load>
                        </button>
                        <button
                            type='button'
                            className='btn warning outline'
                            onClick={() => complete('close')}
                        >
                            Cancel
                        </button>
                    </div>
                    {error ? (
                        <div className='alert-danger published'>
                            <strong>Oh no!</strong> {error}
                        </div>
                    ) : null}
                </section>
            </form>
        </div>
    );
}
