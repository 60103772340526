import React, { createContext, useState, useEffect } from 'react';
import Sidebar from '../screens/Sidebar/Sidebar';

export const ShrinkSidebarContext = createContext();
export const ShowSidebarContext = createContext();

const SidebarProvider = ({ children }) => {
    const [shrinkMenu, setShrinkMenu] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 900) {
            setShrinkMenu(false);
        }
    }, [shrinkMenu]);

    return (
        <ShrinkSidebarContext.Provider value={[shrinkMenu, setShrinkMenu]}>
            <ShowSidebarContext.Provider value={[showMenu, setShowMenu]}>
                <Sidebar>{children}</Sidebar>
            </ShowSidebarContext.Provider>
        </ShrinkSidebarContext.Provider>
    );
};
export default SidebarProvider;
