import { useState, useEffect } from 'react';

const useTextFile = (url) => {
    const [fileText, setFileText] = useState({});
    useEffect(() => {
        const fetchFile = async () => {
            const file = await window.fetch(url);
            const body = await file.text();
            setFileText(body);
        };
        fetchFile();
    }, [url]);
    return fileText;
};

export default useTextFile;
