/* eslint-disable no-extra-boolean-cast */
import React, { createContext, useEffect, useState } from 'react';
import app from '../base';
import SplashScreen from '../components/splashScreen';

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
    const [account, setAccount] = useState(false);
    const [user, setUser] = useState(false);
    const [timedLoad, setTimedLoad] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = app.auth().onAuthStateChanged((authUser) => {
            setLoading(!!authUser);

            if (!!authUser) {
                setUser({
                    loggedIn: !!authUser,
                    email: authUser.email,
                    displayName: authUser.displayName,
                    uid: authUser.uid
                });

                app.firestore()
                    .collection('churches')
                    .where('admins', 'array-contains', authUser.email)
                    .onSnapshot((snapshot) => {
                        if (snapshot.size === 0) {
                            console.log('set loading', false);
                            return setLoading(false);
                        }

                        const newRecord = {
                            id: snapshot.docs[0].id,
                            ...snapshot.docs[0].data()
                        };
                        setAccount(newRecord);
                        setLoading(false);
                    });
            } else {
                // ** catch logout without sending updates nonstop
                if (user !== false) {
                    setAccount(false);
                    setUser(false);
                }
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    setTimeout(() => {
        setTimedLoad(false);
    }, 1000);

    return (
        <AuthContext.Provider
            value={{
                account,
                user,
                loggedIn: !!account,
                loading,
                isPaid:
                    account.subscriptionId ||
                    (account.subscription &&
                        account.subscription.current &&
                        account.subscription.bracket !== 'free')
            }}
        >
            <SplashScreen showLoad={timedLoad}>{children}</SplashScreen>
        </AuthContext.Provider>
    );
};
export default AuthProvider;
