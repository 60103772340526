import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../hooks/AuthContext';

const ProtectedRoute = ({ children, ...rest }) => {
    const auth = useContext(AuthContext);

    if (auth.loading === false && auth.loggedIn === true) {
        return <Route {...rest}>{children}</Route>;
    } else if (auth.loading === false && auth.loggedIn === false) {
        return <Redirect to='/login' />;
    } else {
        return <div></div>;
    }
};

export default ProtectedRoute;
