import React, { useContext, useState } from 'react';
import { SeatMap } from '@churchreserve/cavea';
import '@churchreserve/cavea/dist/index.css';
import useTextFile from '../../hooks/useTextFile';
import { ServiceContext } from './Service';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

export default function ServiceMap({
    reservations,
    selections,
    resSelected,
    mapUrl
}) {
    const xmlText = useTextFile(mapUrl);
    const { setSeats } = useContext(ServiceContext);
    const [numbering, setNumbering] = useState(false);

    const handleSelection = (seats) => {
        console.log(seats);
        resSelected(seats);
    };

    const setNumbers = (e) => {
        if (e.target.checked) {
            setNumbering({
                view: true,
                section: false,
                row: true,
                seat: true
            });
        } else {
            setNumbering(false);
        }
    };

    return (
        <div className='serviceMap'>
            {mapUrl ? (
                <>
                    <div className='seat-numbber-toggle'>
                        <Toggle
                            id='seatNumberToggle'
                            defaultChecked={false}
                            onChange={setNumbers}
                            className='seat-number-toggle-switch'
                        />
                        &nbsp;
                        <label htmlFor='seatNumberToggle'>
                            Show Seat Numbers
                        </label>
                    </div>
                    <SeatMap
                        reservations={reservations || []}
                        onSelect={handleSelection}
                        mapTemplate={xmlText}
                        selections={selections}
                        admin
                        openSeats={setSeats}
                        showNumbers={numbering}
                    />
                </>
            ) : (
                <div className='no-map-selected-title'>No Map Selected</div>
            )}
        </div>
    );
}
