import app from '../base';

const setFileInStorage = async (file, fileName, directory) => {
    const storageRef = await app
        .storage()
        .ref()
        .child(`${directory}/${fileName}`)
        .put(file)
        .then(async (snapshot) => {
            return await snapshot.ref.getDownloadURL();
        })
        .catch((error) => error);

    return storageRef;
};

export default setFileInStorage;
