import React, { useContext, useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { AuthContext } from '../../hooks/AuthContext';
import { useCollection } from 'react-firebase-hooks/firestore';
import app from '../../base';

export default function Guests() {
    const pageLimit = 20;
    const auth = useContext(AuthContext);
    const [guests, setGuests] = useState(false);
    const [pageError, setPageError] = useState(false);
    const [pageList, setPageList] = useState([]);
    const [pageNum, setPageNum] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [snapshot, loading, error] = useCollection(
        app
            .firestore()
            .collection('users')
            .where('churches', 'array-contains', auth.account.id)
    );

    useEffect(() => {
        if (!loading && !error) {
            const temp = snapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id
            }));
            setGuests(temp);
            setPageCount(Math.ceil(temp.length / pageLimit));
        } else {
            setPageError(error);
        }
    }, [snapshot, error, loading]);

    useEffect(() => {
        if (guests.length > 0) {
            window.scrollTo(0, 0);
            const start = pageNum * pageLimit;
            setPageList(guests.slice(start, start + pageLimit));
        }
    }, [guests, pageNum]);

    return (
        <div className='page-container'>
            <h3 className='text-main'>Guests</h3>
            <h5>These are all the guests that have visited your church.</h5>
            {pageError && (
                <div className='alert-danger'>
                    <strong>Oh no!</strong> {error}
                </div>
            )}
            {pageList.length > 0 && (
                <>
                    <div className='card'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loading &&
                                    pageList.map((guest, i) => (
                                        <tr key={guest.email + i}>
                                            <td>{guest.name}</td>
                                            <td>{guest.email}</td>
                                            <td>{guest.phone}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        previousLabel='Previous'
                        nextLabel='Next'
                        breakLabel='...'
                        breakClassName='break-me'
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={(data) => setPageNum(data.selected)}
                        containerClassName='paginate'
                        nextLinkClassName='btn dark'
                        previousLinkClassName='btn dark'
                        pageLinkClassName='btn outline primary'
                        activeLinkClassName='active'
                    />
                </>
            )}
            {guests.length === 0 && loading === false ? (
                <h5>You haven't had any guests yet</h5>
            ) : null}
        </div>
    );
}
