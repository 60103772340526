import React, { useState, useEffect, useContext } from 'react';
import logo from '../../img/white-logo-horz.png';
import { Link, Redirect } from 'react-router-dom';
import LoginForm from './createForms/loginForm';
import { AuthContext } from '../../hooks/AuthContext';

const Login = () => {
    const auth = useContext(AuthContext);
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        if (auth.loggedIn === true) {
            setLoggedIn(true);
        }
    }, [auth]);

    if (loggedIn === true) {
        return <Redirect to='/admin/services' />;
    }

    return (
        <div className='login-create'>
            <div className='twoThird blue-back has-brand'>
                <a className='brand' href='https://churchreserve.com'>
                    <img src={logo} alt='churchreserve logo' />
                </a>
                <h3>Welcome Back</h3>
                <div className='forms'>
                    <div className='card'>
                        <LoginForm />
                    </div>
                </div>
            </div>
            <div className='third'>
                <div className='body vert-half-top'>
                    <h3>Join ChurchReserve</h3>
                    <p>
                        Take the first step towards keeping your congregation
                        safe while meeting in person.
                    </p>
                    <Link className='btn big' to='/create-account'>
                        Sign Up
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Login;
