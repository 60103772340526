import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/analytics';

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    applicationId: process.env.REACT_APP_APPLICATION_ID,
    projectId: process.env.REACT_APP_PROJECT_ID,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APPLICATION_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
});

// if (window.location.hostname === 'localhost') {
//     // app.firestore().settings({
//     //     host: 'localhost:8080',
//     //     ssl: false
//     // });
// }
app.analytics();

export const writeDoc = async (collection, docId, record, merge = true) => {
    if (docId != null) {
        const resp = await app
            .firestore()
            .collection(collection)
            .doc(docId)
            .set(record, { merge: merge });
        return resp;
    } else {
        const resp = await app.firestore().collection(collection).add(record);
        return resp;
    }
};

export const deleteDoc = async (collection, docId) => {
    if (docId != null) {
        const resp = await app
            .firestore()
            .collection(collection)
            .doc(docId)
            .delete();
        return resp;
    } else {
        return null;
    }
};

export default app;
