import React, { useState } from 'react';
import logo from '../../img/white-logo-horz.png';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Load from '../../components/interiorLoader';
import app from '../../base';

const ForgotPassword = () => {
    const [sendComplete, setSendComplete] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const sendReset = (values) => {
        var actionCodeSettings = {
            url: 'https://app.churchreserve.com/login',
            handleCodeInApp: false
        };

        app.firebase_
            .auth()
            .sendPasswordResetEmail(
                values.email.toLowerCase().trim(),
                actionCodeSettings
            )
            .then((success) => {
                setSendComplete(true);
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
                setError(error.message);
                console.log(error);
            });
    };

    return (
        <div className='login-create'>
            <div className='twoThird blue-back has-brand'>
                <a className='brand' href='https://churchreserve.com'>
                    <img src={logo} alt='churchreserve logo' />
                </a>
                <h3>Welcome Back</h3>
                <div className='forms'>
                    <div className='card'>
                        {sendComplete === false ? (
                            <Formik
                                initialValues={{ email: '' }}
                                validationSchema={Yup.object({
                                    email: Yup.string()
                                        .email(
                                            'Please enter a valid email address'
                                        )
                                        .required(
                                            'Please enter an email address'
                                        )
                                })}
                                onSubmit={(values) => {
                                    sendReset(values);
                                }}
                            >
                                <Form className='form'>
                                    <div className='header'>
                                        <h4>Reset Your Password</h4>
                                    </div>
                                    {error && (
                                        <div className='alert-danger'>
                                            <strong>Oh no!</strong> {error}
                                        </div>
                                    )}
                                    <div className='form-section'>
                                        <label htmlFor='email'>
                                            Email Address
                                        </label>
                                        <Field
                                            name='email'
                                            type='email'
                                            className='form-control'
                                        />
                                        <ErrorMessage
                                            name='email'
                                            render={(msg) => (
                                                <div className='field-error'>
                                                    {msg}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <button
                                        type='submit'
                                        className='btn accent'
                                    >
                                        <Load loading={loading}>
                                            Send Reset Password Email
                                        </Load>
                                    </button>
                                    <Link className='btn link' to='/login'>
                                        Back to Login
                                    </Link>
                                </Form>
                            </Formik>
                        ) : (
                            <>
                                <div className='alert-success'>
                                    An email has been sent to reset your
                                    password
                                </div>
                                <Link className='btn dark' to='/login'>
                                    Sign In
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className='third'>
                <div className='body vert-half-top'>
                    <h3>Join ChurchReserve</h3>
                    <p>
                        Take the first step towards keeping your congregation
                        safe while meeting in person.
                    </p>
                    <Link className='btn big' to='/create-account'>
                        Sign Up
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
