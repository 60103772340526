import React, { useState, useEffect } from 'react';
import SortableTable from '../../components/sortableTable';
import { Redirect, Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

const Past = ({ services }) => {
    const pageLimit = 10;
    const [clone, setClone] = useState(false);
    const [pageList, setPageList] = useState([]);
    const [pageNum, setPageNum] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        if (services.length > 0) {
            services.sort((a, b) => (a.date < b.date ? 1 : -1));
            setPageList(
                services.slice(
                    pageNum * pageLimit,
                    pageNum * pageLimit + pageLimit
                )
            );
        }
    }, [services, pageNum]);

    useEffect(() => {
        setPageCount(Math.ceil(services.length / pageLimit));
    }, [services]);

    const cloneService = (service) => {
        window.sessionStorage.setItem('cloneService', JSON.stringify(service));
        setClone(service.id);
    };

    if (clone) {
        return <Redirect to={`../create/${clone}`} />;
    }

    if (services.length > 0) {
        return (
            <>
                <div className='card'>
                    <SortableTable>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th className='hidden-mobile'>Map</th>
                                <th className='hidden-mobile'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pageList.map((service) => (
                                <tr key={service.id}>
                                    <td>
                                        <Link to={`../service/${service.id}`}>
                                            {service.name}
                                        </Link>
                                    </td>
                                    <td>{service.dateStr.date}</td>
                                    <td>{service.dateStr.time}</td>
                                    <td className='hidden-mobile'>
                                        {service.map
                                            ? service.map.name
                                            : 'No map'}
                                    </td>
                                    <td className='hidden-mobile'>
                                        <button
                                            className='btn link'
                                            onClick={() =>
                                                cloneService(service)
                                            }
                                        >
                                            Clone
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </SortableTable>
                </div>
                <ReactPaginate
                    previousLabel='Previous'
                    nextLabel='Next'
                    breakLabel='...'
                    breakClassName='break-me'
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(data) => setPageNum(data.selected)}
                    containerClassName='paginate'
                    nextLinkClassName='btn dark'
                    previousLinkClassName='btn dark'
                    pageLinkClassName='btn outline primary'
                    activeLinkClassName='active'
                />
            </>
        );
    } else {
        return <h5>You have no past services</h5>;
    }
};

export default Past;
