import React, { useState } from 'react';
import ServiceCard from '../../components/serviceCard';
import { Link } from 'react-router-dom';

const Scheduled = ({ services }) => {
    const [empty, setEmpty] = useState(false);

    setTimeout(() => {
        if (services.length === 0) {
            setEmpty(true);
        }
    }, 500);

    if (services.length > 0) {
        return (
            <div className='services-list'>
                {services.map((item, i) => (
                    <ServiceCard service={item} key={i} />
                ))}
            </div>
        );
    } else if (empty === true) {
        return (
            <>
                <h5>You have no scheduled services</h5>
                <br />
                <Link to='../create' className='btn accent'>
                    Create a new serivce here
                </Link>
            </>
        );
    } else {
        return null;
    }
};

export default Scheduled;
