import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPlaceOfWorship } from '@fortawesome/free-solid-svg-icons';
import { Formik, useFormik } from 'formik';
import { writeDoc } from '../../base';
import setFileInStorage from '../../hooks/setFileInStorage';
import { AuthContext } from '../../hooks/AuthContext';
import Load from '../../components/interiorLoader';

export default function ChurchDetails() {
    const [canSubmit, setCanSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const auth = useContext(AuthContext);

    const copyToClipboard = (e) => {
        const link = e.target;
        link.select();
        document.execCommand('copy');
    };

    const uploadImage = async (e) => {
        setLoading(true);
        const file = e.currentTarget.files[0];
        const logoUrl = await setFileInStorage(
            file,
            `logo.${file.name.split('.').pop()}`,
            auth.account.id
        );
        await writeDoc('churches', auth.account.id, {
            image: logoUrl
        });
        setLoading(false);
    };

    const submitForm = async ({ name, email, logoUrl, logoFile }) => {
        setLoading(true);

        if (logoFile) {
            logoUrl = await setFileInStorage(
                logoFile,
                `logo.${logoFile.name.split('.').pop()}`,
                auth.account.id
            );
        }

        if (
            name !== auth.account.name ||
            email !== auth.account.email ||
            logoUrl !== auth.account.image
        ) {
            await writeDoc('churches', auth.account.id, {
                name: name,
                email: email,
                image: logoUrl
            });
            setLoading(false);
            setComplete(true);
        }
    };

    const formik = useFormik({
        initialValues: {
            name: auth.account.name || '',
            email: auth.account.email || '',
            logoUrl: auth.account.image || '',
            logoFile: null
        },
        onSubmit: (values) => {
            submitForm(values);
        },
        validate: (values) => {
            console.log(values);
            console.log('running validation');
            const errors = {};
            if (!values.name) {
                errors.name = 'Required';
            } else if (values.name !== auth.account.name) {
                setCanSubmit(true);
            }

            if (!values.email) {
                errors.email = 'Required';
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
                errors.email = 'Invalid email address';
            } else if (values.email !== auth.account.email) {
                setCanSubmit(true);
            }

            if (values.logoFile != null) {
                setCanSubmit(true);
            }
        },
        validateOnChange: true,
        enableReinitialize: true
    });

    return (
        <div className='church details'>
            {complete && (
                <div className='alert-success'>
                    <strong>Saved!</strong> Your church's information is
                    updated.
                </div>
            )}
            <div className='serviceSetting'>
                <div className='details grid w-50'>
                    <div className='card'>
                        <Formik enableReinitialize='true'>
                            <form
                                onSubmit={formik.handleSubmit}
                                className='form'
                            >
                                <section className='form-section service-link'>
                                    <h5>Landing Page Link</h5>
                                    <div className='input-icon'>
                                        <FontAwesomeIcon icon={faCopy} />
                                    </div>
                                    <input
                                        id='service-link'
                                        name='service-link'
                                        type='text'
                                        className='form-control clickable'
                                        value={`${auth.account.url}.churchreserve.com`}
                                        onClick={copyToClipboard}
                                        readOnly
                                    />
                                </section>
                                <br />
                                <div className='line full-width' />
                                <section className='form-section'>
                                    <h4>Details</h4>
                                    <div className='form-section'>
                                        <label htmlFor='name'>
                                            Church Name
                                        </label>
                                        <input
                                            id='name'
                                            name='name'
                                            type='text'
                                            className='form-control'
                                            onChange={formik.handleChange}
                                            value={formik.values.name}
                                        />
                                    </div>
                                    {/* <div className='form-section'>
                                        <label htmlFor='email'>
                                            Church Support Email
                                        </label>
                                        <input
                                            id='email'
                                            name='email'
                                            type='email'
                                            className='form-control'
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                        />
                                    </div> */}
                                    <div className='form-section'>
                                        <label htmlFor='editLogo'>
                                            Church Logo
                                        </label>
                                        <div id='editLogo' name='editLogo'>
                                            {auth.account.image ? (
                                                <img
                                                    id='logo'
                                                    name='logo'
                                                    className='thumbnail'
                                                    src={auth.account.image}
                                                    alt='Chuch logo here'
                                                />
                                            ) : (
                                                <div
                                                    className='icon'
                                                    id='logo'
                                                    name='logo'
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faPlaceOfWorship}
                                                        size='lg'
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <input
                                            id='logoFile'
                                            name='logoFile'
                                            type='file'
                                            className='inputfile'
                                            onChange={(event) =>
                                                uploadImage(event)
                                            }
                                        />
                                        <label htmlFor='logoFile'>
                                            Change/Upload Image
                                        </label>
                                    </div>
                                    <div className='form-section' />
                                </section>
                                <button
                                    type='submit'
                                    className='btn primary outline'
                                    disabled={!canSubmit}
                                >
                                    <Load loading={loading}>Submit</Load>
                                </button>
                            </form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}
