import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMicrophone,
    faPlusCircle,
    faPlaceOfWorship,
    faQuestionCircle,
    faChevronLeft,
    faCommentDollar,
    faLaptop,
    faEnvelope,
    faGraduationCap
} from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

const SidebarNavLink = ({ to, icon, text, external = false }) => {
    const [faIcon, setFaIcon] = useState(null);

    useEffect(() => {
        switch (icon) {
            case 'microphone':
                return setFaIcon(faMicrophone);
            case 'plus':
                return setFaIcon(faPlusCircle);
            case 'church':
                return setFaIcon(faPlaceOfWorship);
            case 'question':
                return setFaIcon(faQuestionCircle);
            case 'leftArrow':
                return setFaIcon(faChevronLeft);
            case 'dollar':
                return setFaIcon(faCommentDollar);
            case 'laptop':
                return setFaIcon(faLaptop);
            case 'envelope':
                return setFaIcon(faEnvelope);
            case 'training':
                return setFaIcon(faGraduationCap);
            default:
                break;
        }
    }, [icon]);

    if (external) {
        return (
            <a href={external} target='_blank' rel='noreferrer'>
                <li className='help'>
                    <div className='icon'>
                        {faIcon && <FontAwesomeIcon icon={faIcon} size='lg' />}
                    </div>
                    <div className='text'>{text}</div>
                </li>
            </a>
        );
    }
    return (
        <NavLink to={to} activeClassName='sidebar-active'>
            <li>
                <div className='icon'>
                    {faIcon && <FontAwesomeIcon icon={faIcon} size='lg' />}
                </div>
                <div className='text'>{text}</div>
            </li>
        </NavLink>
    );
};
export default SidebarNavLink;
