import { useState, useEffect } from 'react';
import app from '../base';

const useCollectionSnapshot = (collectionName, queryObj) => {
    const [records, setRecords] = useState([]);

    useEffect(() => {
        let collection = app.firestore().collection(collectionName);

        if (queryObj != null) {
            collection = collection.where(
                queryObj.field,
                queryObj.operator || '==',
                queryObj.value
            );
        }
        const unsubscribe = collection.onSnapshot((snapshot) => {
            const newRecords = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));

            setRecords(newRecords);
        });

        return unsubscribe;
    }, [collectionName, queryObj]);
    return records;
};

export default useCollectionSnapshot;
