import React, { useState, useContext } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import app from '../../../base';
import Load from '../../../components/interiorLoader';
import { AuthContext } from '../../../hooks/AuthContext';
import { Link } from 'react-router-dom';

const LoginForm = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const auth = useContext(AuthContext);

    const google = new app.firebase_.auth.GoogleAuthProvider();

    const providerLogin = () => {
        setLoading(true);
        setError('');
        app.firebase_
            .auth()
            .signInWithPopup(google)
            .then(function (result) {
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                if (error.code !== 'auth/popup-closed-by-user') {
                    setError(error.message);
                }
                setLoading(false);
            });
    };

    const emailLogin = (values) => {
        setLoading(true);
        setError('');
        app.firebase_
            .auth()
            .signInWithEmailAndPassword(
                values.email.toLowerCase().trim(),
                values.password.trim()
            )
            .then((success) => {
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
                setError(error.message);
                console.log(error);
            });
    };

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={Yup.object({
                email: Yup.string()
                    .email('Please enter a valid email address')
                    .required('Please enter an email address'),
                password: Yup.string().required('Password is Required')
            })}
            onSubmit={(values) => {
                emailLogin(values);
            }}
        >
            <Form className='form'>
                <div className='header'>
                    <h4>Sign into ChurchReserve</h4>
                </div>
                {error !== '' && (
                    <div className='alert-danger'>
                        <strong>Oh no!</strong> {error}
                    </div>
                )}
                {auth.loading === false &&
                auth.loggedIn === false &&
                auth.user !== false ? (
                    <div className='alert-accent'>
                        <strong>Not Connected To A Church:</strong> Looks like
                        you have an account, but you need to connect it to a
                        church. If you are supposed to be connected to a church,
                        let your admin know. Otherwise, head over to Sign Up!
                    </div>
                ) : null}
                <div className='form-section'>
                    <div className='grid-wrapper provider-signin'>
                        <button
                            className='btn outline withIcon'
                            onClick={providerLogin}
                            type='button'
                        >
                            <Load loading={loading}>
                                <img
                                    className='provider-icon'
                                    src='https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg'
                                    alt='google logo'
                                />
                                Google
                            </Load>
                        </button>
                    </div>
                </div>
                <div className='form-section login-break'>
                    <div className='line' />
                    <div className='or'>or</div>
                </div>
                <div className='form-section'>
                    <label htmlFor='email'>Email Address</label>
                    <Field name='email' type='email' className='form-control' />
                    <ErrorMessage
                        name='email'
                        render={(msg) => (
                            <div className='field-error'>{msg}</div>
                        )}
                    />
                </div>
                <div className='form-section'>
                    <label htmlFor='password'>Password</label>
                    <Field
                        name='password'
                        type='password'
                        className='form-control'
                    />
                    <ErrorMessage
                        name='password'
                        render={(msg) => (
                            <div className='field-error'>{msg}</div>
                        )}
                    />
                </div>
                <button type='submit' className='btn success'>
                    <Load loading={loading}>Sign In</Load>
                </button>
                <Link className='btn link' to='/reset-password'>
                    Forgot My Password
                </Link>
            </Form>
        </Formik>
    );
};

export default LoginForm;
