import React, { useContext, useEffect, useState } from 'react';
import SubscriptionCard from '../../components/subscriptionCard';
import { AuthContext } from '../../hooks/AuthContext';
import { Link } from 'react-router-dom';

export default function CurrentPlan({ manageBilling }) {
    const auth = useContext(AuthContext);
    const [grandfather, setGrandfather] = useState(false);
    const [isCurrent, setIsCurrent] = useState(true);

    useEffect(() => {
        if (auth.account) {
            console.log('grandfather ', grandfather);

            setGrandfather(
                auth.account.usageId !== undefined ||
                    auth.account.subscriptionId !== undefined
            );
        }

        if (auth.account.subscription) {
            setIsCurrent(auth.account.subscription.current);
        }
    }, [auth.account, grandfather]);

    if (grandfather) {
        return (
            <>
                {auth.account.subscription && !isCurrent ? (
                    <div>
                        <div className='alert-danger'>
                            <strong>Your Payment has Failed!</strong> To
                            continue using ChurchReserve you'll need to
                            re-subscribe to a subscription plan with the current
                            pricing.
                        </div>
                        <Link className='btn dark' to='billing/subscriptions'>
                            Upgrade Your Account
                        </Link>
                    </div>
                ) : (
                    <div>
                        <h4>You have been grandfathered in!</h4>
                        <p>
                            You are currently paying a rate that we no longer
                            offer. You're able to continue at that rate as long
                            as your subscription is active! If you'd like to end
                            or change subscription, you'll be subject to the
                            current pricing.
                        </p>
                        <br />
                        <Link
                            className='btn accent block'
                            to='billing/subscriptions'
                        >
                            View Current Pricing
                        </Link>
                    </div>
                )}
            </>
        );
    }

    return (
        <>
            {!isCurrent && (
                <div className='alert-danger'>
                    <strong>Attention Needed! </strong>Your most recent payment
                    has failed. Please rectify your subscription to continue
                    using ChurchReserve.&nbsp;&nbsp;
                    <button
                        className='btn white outline'
                        onClick={manageBilling}
                    >
                        Fix Payment
                    </button>
                </div>
            )}
            <div className='current-plan'>
                <div className='plan'>
                    <div className='off-card-title'>Your Plan</div>
                    <SubscriptionCard
                        usePaid={auth.isPaid}
                        plan={auth.account.subscription}
                    />
                    <div className='off-card-extras'>
                        {auth.isPaid && (
                            <button
                                className={`btn outline block ${
                                    !isCurrent ? 'alert' : 'dark'
                                }`}
                                to='billing/subscriptions'
                                onClick={manageBilling}
                            >
                                Manage Subscription
                            </button>
                        )}
                        {isCurrent && (
                            <Link
                                className='btn accent block'
                                to='billing/subscriptions'
                            >
                                Upgrade Now
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
