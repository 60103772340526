import React, { useState, useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import DateTimePicker from 'react-datetime-picker';
import * as Yup from 'yup';
import Load from '../components/interiorLoader';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import { writeDoc } from '../base';
import { AuthContext } from '../hooks/AuthContext';

export default function CreateServiceForm({ serviceObj, complete }) {
    const auth = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [noMapRes, setNoMapRes] = useState(false);
    const [autoDistance, setDistance] = useState(false);
    const [loading, setLoading] = useState(false);
    const [trial] = useState(!auth.isPaid);
    const [cancelClone, setCancelClone] = useState(false);

    const submitForm = async (form) => {
        setLoading(true);

        const updatedValues = {
            ...form,
            date: form.date.getTime()
        };

        const serviceRec = {
            churchId: auth.account.id,
            dateStr: {
                date: format(updatedValues.date, 'MMM do, y'),
                time: format(updatedValues.date, 'h:mmaaaa')
            },
            date: updatedValues.date,
            name: updatedValues.name,
            reservationType: updatedValues.reservationType,
            status: 'draft',
            socialDistancing: {
                capacityType: noMapRes ? 'number' : 'percent',
                capacity: updatedValues.capacity,
                seats: updatedValues.seats
            }
        };

        if (!noMapRes) {
            serviceRec.map = auth.account.maps.find(
                (map) => map.url === form.map
            );
        }

        if (serviceRec.name === '') {
            serviceRec.name = `${serviceRec.dateStr.date} - ${serviceRec.dateStr.time}`;
        }

        let createdService;
        try {
            createdService = await writeDoc('services', null, serviceRec);
        } catch (err) {
            console.log(err);
            setError(err.message);
            setLoading(false);
            return;
        }

        if (complete) {
            complete(createdService.id);
        }
    };

    const formik = useFormik({
        initialValues: {
            name: serviceObj.name || '',
            date: serviceObj.date
                ? new Date(serviceObj.date)
                : addDays(new Date(), 5),
            map: serviceObj.map ? serviceObj.map.url : '',
            reservationType: serviceObj.reservationType || 0,
            capacity: serviceObj.socialDistancing
                ? serviceObj.socialDistancing.capacity
                : 100,
            seats: serviceObj.socialDistancing
                ? serviceObj.socialDistancing.seats
                : 0
        },
        validationSchema: Yup.object({
            name: Yup.string().max(80, 'The service name is too long'),
            date: Yup.date().required('Choosing a date and time is required'),
            reservationType: Yup.number()
                .moreThan(0, 'You must select a reservation type')
                .required('Required'),
            map: Yup.string().when('reservationType', {
                is: (reservationType) => reservationType < 4,
                then: (s) => s.required('You must select a seating map')
            }),
            capacity: Yup.number()
                .moreThan(0, 'You must set your capacity greater than 0')
                .required('You must choose a capacity'),
            seats: Yup.number()
        }),
        onSubmit: (values) => {
            submitForm(values);
        },
        enableReinitialize: false
    });

    useEffect(() => {
        if (!auth.isPaid) {
            formik.setFieldValue('reservationType', 4);
            console.log(serviceObj);
            if (
                serviceObj.socialDistancing &&
                serviceObj.socialDistancing.reservationType !== 4
            ) {
                setCancelClone(true);
            }
        }
    }, []);

    useEffect(() => {
        if (formik.values.reservationType === 4) {
            setNoMapRes(true);
        } else {
            setNoMapRes(false);
            formik.setFieldValue('seats', 0);
            if (formik.values.capacity > 100) {
                formik.setFieldValue('capacity', 100);
            }
        }

        if (formik.values.reservationType === 3) {
            setDistance(true);
        } else {
            setDistance(false);
        }
    }, [formik.values.reservationType]);

    return (
        <div className='service-form'>
            {cancelClone && (
                <div className='alert-danger'>
                    We could not clone the service you requested - you must have
                    an active subscription.
                </div>
            )}
            <form onSubmit={formik.handleSubmit} className='form'>
                <section>
                    <h5>Details</h5>
                    <div className='form-section'>
                        <label htmlFor='date'>Date and Time of service</label>
                        <DateTimePicker
                            id='date'
                            name='date'
                            className='special-input'
                            onChange={(value) => {
                                formik.setFieldValue('date', value);
                            }}
                            value={formik.values.date}
                            calendarIcon={null}
                            clearIcon={null}
                        />
                        {formik.touched.date && formik.errors.date ? (
                            <div className='field-error'>
                                {formik.errors.date}
                            </div>
                        ) : null}
                    </div>
                    <div className='form-section'>
                        <label htmlFor='name'>Name</label>
                        <input
                            id='name'
                            name='name'
                            type='string'
                            className='form-control'
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            placeholder='Leave blank for a "Date - Time" name'
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div className='field-error'>
                                {formik.errors.name}
                            </div>
                        ) : null}
                    </div>
                </section>
                <section>
                    <div className='title'>
                        <h5>Reservations Types</h5>
                        {trial && (
                            <p className='text-alert'>
                                Invitations Only is the only reservation type
                                available in the free tier.
                            </p>
                        )}
                    </div>
                    <div className='form-section'>
                        <label className='checkbox'>
                            <input
                                name='reservationType'
                                type='radio'
                                className='checkbox'
                                onChange={() =>
                                    formik.setFieldValue('reservationType', 4)
                                }
                                checked={formik.values.reservationType === 4}
                                value={4}
                            />
                            Invitations Only
                        </label>
                        <p className='text-light-gray'>
                            This option doesn't need a seating map - your guests
                            will submit their seating request with a total
                            number of attendants.
                        </p>
                    </div>
                    <div className='form-section'>
                        <label className='checkbox'>
                            <input
                                name='reservationType'
                                type='radio'
                                className='checkbox'
                                onChange={() =>
                                    formik.setFieldValue('reservationType', 1)
                                }
                                checked={formik.values.reservationType === 1}
                                value={1}
                                disabled={trial}
                            />
                            Admin Seating
                        </label>
                        <p className='text-light-gray'>
                            Your guests will only be able to send requests for
                            participation in this service. All seat assignments
                            will be handled by an admin (no auto-social
                            distancing)
                        </p>
                    </div>
                    <div className='form-section'>
                        <label className='checkbox'>
                            <input
                                name='reservationType'
                                type='radio'
                                className='checkbox'
                                onChange={() =>
                                    formik.setFieldValue('reservationType', 2)
                                }
                                checked={formik.values.reservationType === 2}
                                value={2}
                                disabled={trial}
                            />
                            Verified Seating
                        </label>
                        <p className='text-light-gray'>
                            Your guests will be able to select their seats, but
                            all requests are verified by an admin. Any conflicts
                            will have to be solved by an admin, as well. (no
                            auto-social distancing)
                        </p>
                    </div>
                    <div className='form-section'>
                        <label className='checkbox'>
                            <input
                                name='reservationType'
                                type='radio'
                                className='checkbox'
                                onChange={() =>
                                    formik.setFieldValue('reservationType', 3)
                                }
                                checked={formik.values.reservationType === 3}
                                value={3}
                                disabled={trial}
                            />
                            Guest Selection Seating
                        </label>
                        <p className='text-light-gray'>
                            Your guests will be able to reserve their seats in a
                            first-come, first-served manner, with auto-social
                            distancing
                        </p>
                    </div>
                    {formik.touched.reservationType &&
                    formik.errors.reservationType ? (
                        <div className='field-error'>
                            {formik.errors.reservationType}
                        </div>
                    ) : null}
                </section>
                <section>
                    <div className='line full-width' />
                    <h5 className='title'>Social Distancing</h5>
                    {!noMapRes && (
                        <div className='form-section'>
                            <label htmlFor='map'>Seating Map</label>
                            <select
                                id='map'
                                name='map'
                                type='string'
                                className='form-control'
                                onChange={formik.handleChange}
                                value={formik.values.map}
                            >
                                <option value={null}>Select a Map</option>
                                {auth.account.maps &&
                                    auth.account.maps.map((item) => (
                                        <option
                                            key={item.name}
                                            value={item.url}
                                        >
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                            {formik.touched.map && formik.errors.map ? (
                                <div className='field-error'>
                                    {formik.errors.map}
                                </div>
                            ) : null}
                        </div>
                    )}
                    <div className='form-section'>
                        <label htmlFor='name'>
                            {formik.values.reservationType === 4
                                ? 'Number '
                                : 'Percent '}
                            of Available Seats
                        </label>
                        <input
                            id='capacity'
                            name='capacity'
                            type='number'
                            className='form-control'
                            onChange={formik.handleChange}
                            value={formik.values.capacity}
                        />
                        {formik.touched.capacity && formik.errors.capacity ? (
                            <div className='field-error'>
                                {formik.errors.capacity}
                            </div>
                        ) : null}
                    </div>
                    {!noMapRes && autoDistance && (
                        <div className='form-section'>
                            <label htmlFor='name'>
                                Seats Between Reservations
                            </label>
                            <select
                                id='seats'
                                name='seats'
                                type='string'
                                className='form-control'
                                onChange={formik.handleChange}
                                value={`${formik.values.seats}`}
                            >
                                <option value={0}>No seats</option>
                                <option value={1}>1 seat</option>
                                <option value={2}>2 seats</option>
                                <option value={3}>3 seats</option>
                                <option value={4}>4 seats</option>
                            </select>
                        </div>
                    )}
                </section>
                {Object.keys(formik.errors).map((key) => {
                    if (formik.touched[key] && formik.errors[key]) {
                        return (
                            <div className='alert-danger'>
                                {formik.errors[key]}
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
                <div className='form-section'>
                    <button
                        type='submit'
                        className='btn primary outline'
                        disabled={!(formik.isValid && formik.dirty)}
                    >
                        <Load loading={loading}>Create Service</Load>
                    </button>
                </div>
                {error ? (
                    <div className='alert-danger published'>
                        <strong>Oh no!</strong> {error}
                    </div>
                ) : null}
            </form>
        </div>
    );
}
