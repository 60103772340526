import React, { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { AuthContext } from '../../hooks/AuthContext';
import CurrentPlan from './CurrentPlan';
import Subscriptions from './Subscriptions';

export default function Billing() {
    const match = useRouteMatch();
    const auth = useContext(AuthContext);

    const manageBilling = async () => {
        try {
            const res = await window.fetch(
                `${process.env.REACT_APP_FUNCTIONS_URL}/openCustomerPortal`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        customer:
                            auth.account.customer ||
                            auth.account.subscription.customer,
                        domain: window.location.origin
                    })
                }
            );
            const session = await res.json();
            console.log(session);
            window.location.href = session.url;
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className='page-container'>
            <h3 className='text-main'>Billing</h3>
            <h5 className='sub-title'>
                Straight forward pricing so your church can focus on ministry
                instead of restrictions
            </h5>
            <Switch>
                <Route exact path={`${match.url}/`}>
                    {auth.isPaid === false && (
                        <Redirect to={`${match.url}/subscriptions`} />
                    )}
                    <CurrentPlan manageBilling={manageBilling} />
                </Route>
                <Route exact path={`${match.url}/subscriptions`}>
                    <Subscriptions manageBilling={manageBilling} />
                </Route>
            </Switch>
        </div>
    );
}
