import React, { useState, useEffect, useContext } from 'react';
import ServiceMap from './ServiceMap';
import ReservationActionsList from '../../components/reservationActionsList';
import { writeDoc } from '../../base';
import { ShrinkSidebarContext } from '../../hooks/SidebarContext';
import { ServiceContext } from '../Service/Service';
import Modal from '../../hooks/Modal';
import MakeGuestReservation from '../../forms/makeGuestReservation';

export default function SerivceEditSeating() {
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [resType, setResType] = useState('');
    const [showGuestInfo, setShowGuestInfo] = useState(false);
    const [seatCollision, setSeatCollision] = useState(false);

    // when I mount, collapse the sidebar
    const [shrinkMenu, setShrinkMenu] = useContext(ShrinkSidebarContext);
    const context = useContext(ServiceContext);

    useEffect(() => {
        shrinkMenu || setShrinkMenu(true);
    }, []);

    const resSelected = (seats) => {
        setSelectedSeats(seats);
    };

    const completeSelection = async () => {
        console.log(resType);
        if (resType === 'blockout') {
            blockoutSeats();
        } else if (resType === 'reserve') {
            setShowGuestInfo(true);
        }
    };

    const modalStatus = (status) => {
        if (status === 'close') {
            setShowGuestInfo(false);
            setSelectedSeats([]);
        } else if (status === 'collision') {
            setShowGuestInfo(false);
            setSeatCollision(true);
            setSelectedSeats([]);
        }
    };

    const blockoutSeats = async () => {
        const resOfType = context.reservations.filter(
            (r) => r.status === resType
        );

        console.log(selectedSeats);
        const docId = resOfType.length > 0 ? resOfType[0].id : null;
        const res = {
            seats: selectedSeats,
            status: resType,
            admin: true,
            serviceId: context.service.id,
            churchId: context.service.churchId
        };
        try {
            await writeDoc('reservations', docId, res);
        } catch (e) {
            console.log(e);
        }
        setSelectedSeats([]);
    };

    const selectSeats = (seats) => {
        setSelectedSeats(seats);
    };

    return (
        <div className='service-reservations edit-seating grid-wrapper row'>
            <div className='grid row w-25'>
                <div className='scrolly scrolly-actions'>
                    <ReservationActionsList
                        selectSeats={selectSeats}
                        selectedType={(actionType) => setResType(actionType)}
                        completeSelection={completeSelection}
                    />
                </div>
            </div>
            <ServiceMap
                reservations={context.reservations}
                resSelected={(seats) => resSelected(seats)}
                selections={selectedSeats}
                mapUrl={context.service.map && context.service.map.url}
            />
            <Modal isOpen={showGuestInfo} size='small'>
                <h4>The guest's information</h4>
                <MakeGuestReservation
                    complete={(status) => modalStatus(status)}
                    selectedSeats={selectedSeats}
                    serviceId={context.service.id}
                />
            </Modal>
            <Modal isOpen={seatCollision} size='small'>
                <div style={{ textAlign: 'center' }}>
                    <h2>Oh No!</h2>
                    <h4>These seats have already been reserved</h4>
                    <p>You'll have to select new seats for this reservation</p>
                    <div style={{ textAlign: 'center' }}>
                        <button
                            type='button'
                            className='btn primary'
                            onClick={() => setSeatCollision(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
