import React, { useEffect, useState } from 'react';

const Modal = ({ isOpen, close, children, size }) => {
    const [fadeType, setFadeType] = useState();

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            setFadeType('in');
        } else {
            document.body.style.overflow = 'initial';
            setFadeType('out');
        }

        return function closeModal() {};
    }, [isOpen]);

    if (isOpen) {
        return (
            <div className={`modal-container fade-${fadeType} ${size || ''}`}>
                <div className='modal'>
                    <div className='modal-body'>{children}</div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default Modal;
