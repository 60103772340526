import React, { useEffect } from 'react';
import './App.scss';
import SideBar from './hooks/SidebarContext';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useLocation
} from 'react-router-dom';

import Services from './screens/Services/Services';
import Guests from './screens/Guests/Guests';
import Service from './screens/Service/Service';
import Church from './screens/Church/Church';
import CreateService from './screens/CreateService/CreateService';
import AuthProvider from './hooks/AuthContext';
import Create from './screens/Auth/Create';
import Login from './screens/Auth/Login';
import ProtectedRoute from './components/protectedRoute';
import ForgotPassword from './screens/Auth/ForgotPassword';
import Billing from './screens/Billing/Billing';
import ChurchAdmins from './screens/Church/ChurchAdmins';
import Emailing from './screens/Emailing/Emailing';

function App() {
    return (
        <AuthProvider>
            <Router>
                <ScrollToTop />
                <Switch>
                    <ProtectedRoute path='/admin'>
                        <SideBar>
                            <Route exact path='/admin'>
                                <Redirect to='/admin/services' />
                            </Route>
                            <Route path='/admin/services'>
                                <Services />
                            </Route>
                            <Route path='/admin/guests'>
                                <Guests />
                            </Route>
                            <Route path='/admin/service/:id'>
                                <Service />
                            </Route>
                            <Route path='/admin/create/:id?'>
                                <CreateService />
                            </Route>
                            <Route path='/admin/church'>
                                <Church />
                            </Route>
                            <Route path='/admin/admins'>
                                <ChurchAdmins standalonePage />
                            </Route>
                            <Route path='/admin/billing'>
                                <Billing />
                            </Route>
                            <Route path='/admin/emails'>
                                <Emailing />
                            </Route>
                        </SideBar>
                    </ProtectedRoute>
                    <Route path='/'>
                        <Route exact path='/'>
                            <Redirect to='/login' />
                        </Route>
                        <Route path='/login'>
                            <Login />
                        </Route>
                        <Route path='/reset-password'>
                            <ForgotPassword />
                        </Route>
                        <Route path='/create-account'>
                            <Create />
                        </Route>
                    </Route>
                </Switch>
            </Router>
        </AuthProvider>
    );
}

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default App;
