import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

export default function ServiceCard({ service }) {
    const [clone, setClone] = useState(false);

    const cloneService = (e) => {
        window.sessionStorage.setItem('cloneService', JSON.stringify(service));
        setClone(true);
    };

    const getStatusName = (status) => {
        return status === 'draft' ? 'Private' : 'Public';
    };

    if (clone) {
        return <Redirect to={`../create/${service.id}`} />;
    }
    return (
        <div className='service-card card padding-15-vert'>
            <Link to={`../service/${service.id}`} className='top'>
                <div className='name'>
                    <div className='value'>{service.name}</div>
                </div>
                <div className='datetime'>
                    <div className='date'>
                        <div className='label'>Date</div>
                        <div className='value'>{service.dateStr.date}</div>
                    </div>
                    <div className='time'>
                        <div className='label'>Time</div>
                        <div className='value'>{service.dateStr.time}</div>
                    </div>
                </div>

                <div className='status'>
                    <div className='label'>Status</div>
                    <div className='value'>{getStatusName(service.status)}</div>
                </div>
            </Link>
            <div className='bottom'>
                <div className='actions'>
                    <div className='value'>
                        <button className='btn link' onClick={cloneService}>
                            Clone
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
