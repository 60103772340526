import React, { useContext, useEffect, useState } from 'react';
import { ServiceContext } from '../screens/Service/Service';
import Load from '../components/interiorLoader';

const ServiceHeader = ({ sendNotifs, changeStatus, seats }) => {
    const context = useContext(ServiceContext);
    const [loading, setLoading] = useState(false);
    const [noMapRes, setNoMapRes] = useState(false);
    const [avalableSeats, setAvailableSeats] = useState(false);

    useEffect(() => {
        if (context.service.reservationType > 3) {
            setNoMapRes(true);
        } else {
            for (let i = 0; i < context.reservations.length; i++) {
                if (context.reservations[i].needsUpdateNotif === true) {
                    context.setNotifs(true);
                    break;
                }
            }
        }
    }, [context]);

    useEffect(() => {
        if (!context.service.name) {
            return;
        }

        const totalCapacity =
            context.service.socialDistancing.capacityType === 'number'
                ? context.service.socialDistancing.capacity
                : Math.round(
                      (seats.available + seats.blockout + seats.reserved) *
                          (context.service.socialDistancing.capacity / 100)
                  );

        if (noMapRes) {
            setAvailableSeats(
                totalCapacity - totalRequests(context.details.completed)
            );
        } else if (context.details.completed && seats) {
            const capAndAvailable =
                totalCapacity - totalSeats(context.details.completed);

            if (seats.avalable < capAndAvailable - seats.blockout) {
                setAvailableSeats(seats.avalableSeats);
            } else if (capAndAvailable > seats.available) {
                setAvailableSeats(seats.available);
            } else {
                setAvailableSeats(capAndAvailable);
            }
        }
    }, [noMapRes, seats, context.details]);

    const updateNotifs = () => {
        sendNotifs();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };

    const totalSeats = (list) => {
        if (!list || list.length === 0) {
            return 0;
        }
        return list.reduce((accum, item) => accum + item.seats.length, 0);
    };

    const totalRequests = (list) => {
        if (!list || list.length === 0) {
            return 0;
        }

        return list.reduce((accum, item) => accum + item.requestedSeats, 0);
    };

    return (
        <div className='serviceHeader'>
            <div className='name'>
                <h6>Service</h6>
                <h4 className='text'>{context.service.name}</h4>
            </div>
            <div className='seatingDetails'>
                <div className='total'>
                    <div>Available</div>
                    <div>{avalableSeats}</div>
                </div>
                <div className='reserved'>
                    <div>Complete</div>
                    {context.service.reservationType === 4 ? (
                        <div>{totalRequests(context.details.completed)}</div>
                    ) : (
                        <div>{seats.reserved}</div>
                    )}
                </div>
                {!noMapRes && (
                    <div className='requests'>
                        <div>Requests</div>
                        {context.service.reservationType === 1 ||
                        context.service.reservationType === 4 ? (
                            <div>{totalRequests(context.details.requests)}</div>
                        ) : (
                            <div>{totalSeats(context.details.requests)}</div>
                        )}
                    </div>
                )}
            </div>
            <div className='actions'>
                {context.needsNotifs === true && !noMapRes ? (
                    <button className='btn accent' onClick={updateNotifs}>
                        <Load loading={loading}>Send Updates</Load>
                    </button>
                ) : null}
                {context.service.status === 'draft' && (
                    <>
                        <button
                            className='btn success'
                            onClick={(e) => changeStatus('published')}
                        >
                            Make Public
                        </button>
                        <h5 className='text-dark status-text'>Private</h5>
                    </>
                )}
                {context.service.status === 'published' && (
                    <>
                        <div
                            className='btn dark outline'
                            onClick={(e) => changeStatus('draft')}
                        >
                            Make Private
                        </div>
                        <h5 className='text-success status-text'>Public</h5>
                    </>
                )}
                {context.service.status === 'sample' && (
                    <div className='text-success status-text'>
                        Sample Status
                    </div>
                )}
            </div>
        </div>
    );
};

export default ServiceHeader;
