import React, { useState, useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import DateTimePicker from 'react-datetime-picker';
import * as Yup from 'yup';
import Load from '../components/interiorLoader';
import format from 'date-fns/format';
import { writeDoc } from '../base';
import { AuthContext } from '../hooks/AuthContext';

export default function UpdateServiceForm({ service, complete }) {
    const auth = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [noMapRes, setNoMapRes] = useState(false);
    const [autoDistance, setDistance] = useState(false);

    const submitForm = async (form) => {
        setLoading(true);

        const serviceRec = {
            date: form.date.getTime(),
            dateStr: {
                date: format(form.date, 'MMM do, y'),
                time: format(form.date, 'h:mmaaaa')
            },
            name: form.name,
            reservationType: form.reservationType,
            socialDistancing: {
                capacityType: noMapRes ? 'number' : 'percent',
                capacity: form.capacity,
                seats: form.seats
            }
        };

        if (!noMapRes) {
            serviceRec.map = auth.account.maps.find(
                (map) => map.url === form.map
            );
        }

        // console.log(serviceRec);
        try {
            await writeDoc('services', service.id, serviceRec);
            complete({ success: true });
        } catch (err) {
            console.log(err);
            setError(err.message);
            setLoading(false);
            complete({ success: false });
        }
    };

    const formik = useFormik({
        initialValues: {
            name: service.name,
            date: new Date(service.date),
            map: (service.map && service.map.url) || '',
            reservationType: service.reservationType,
            capacity: service.socialDistancing.capacity,
            seats: service.socialDistancing.seats
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .max(80, 'The service name is too long')
                .required('A name is required'),
            date: Yup.date().required('Choosing a date and time is required'),
            reservationType: Yup.number()
                .moreThan(0, 'You must select a reservation type')
                .required('Required'),
            map: Yup.string().when('reservationType', {
                is: (reservationType) => reservationType < 4,
                then: (s) => s.required('You must select a seating map')
            }),
            capacity: Yup.number()
                .moreThan(0, 'You must set your capacity greater than 0')
                .required('You must choose a capacity'),
            seats: Yup.mixed()
        }),
        onSubmit: (values) => {
            submitForm(values);
        },
        enableReinitialize: true
    });

    useEffect(() => {
        if (formik.values.reservationType === 4) {
            setNoMapRes(true);
        } else {
            setNoMapRes(false);
            if (formik.values.capacity > 100) {
                formik.setFieldValue('capacity', 100);
            }
        }

        // console.log(formik.values.reservationType);
        if (formik.values.reservationType === 3) {
            setDistance(true);
        } else {
            setDistance(false);
        }
    }, [formik]);

    return (
        <div className='service-form'>
            <form onSubmit={formik.handleSubmit} className='form'>
                <section>
                    <h5>Details</h5>
                    <div className='form-section'>
                        <label htmlFor='date'>Date and Time of service</label>
                        <DateTimePicker
                            id='date'
                            name='date'
                            className='special-input'
                            onChange={(value) => {
                                formik.setFieldValue('date', value);
                            }}
                            value={formik.values.date}
                            disabled
                            calendarIcon={null}
                            clearIcon={null}
                        />
                        {formik.touched.date && formik.errors.date ? (
                            <div className='field-error'>
                                {formik.errors.date}
                            </div>
                        ) : null}
                    </div>
                    <div className='form-section'>
                        <label htmlFor='name'>Name</label>
                        <input
                            id='name'
                            name='name'
                            type='string'
                            className='form-control'
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div className='field-error'>
                                {formik.errors.name}
                            </div>
                        ) : null}
                    </div>
                    {!noMapRes && (
                        <div className='form-section'>
                            <label htmlFor='map'>Map</label>
                            <select
                                id='map'
                                name='map'
                                type='string'
                                className='form-control'
                                onChange={formik.handleChange}
                                value={formik.values.map}
                                disabled
                            >
                                <option value={null}>Select a Map</option>
                                {auth.account.maps &&
                                    auth.account.maps.map((item) => (
                                        <option
                                            key={item.name}
                                            value={item.url}
                                        >
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                            {formik.touched.map && formik.errors.map ? (
                                <div className='field-error'>
                                    {formik.errors.map}
                                </div>
                            ) : null}
                        </div>
                    )}
                </section>
                <section>
                    <div className='title'>
                        <h5>Reservations Types</h5>
                    </div>
                    <div className='form-section'>
                        <label className='checkbox'>
                            <input
                                name='reservationType'
                                type='radio'
                                className='checkbox'
                                onChange={() =>
                                    formik.setFieldValue('reservationType', 4)
                                }
                                checked={formik.values.reservationType === 4}
                                value={4}
                                disabled
                            />
                            Invitations Only
                        </label>
                        <p className='text-light-gray'>
                            This option doesn't need a seating map - your guests
                            will submit their seating request as a total number
                            of attendants.
                        </p>
                    </div>
                    <div className='form-section'>
                        <label className='checkbox'>
                            <input
                                name='reservationType'
                                type='radio'
                                className='checkbox'
                                onChange={() =>
                                    formik.setFieldValue('reservationType', 1)
                                }
                                checked={formik.values.reservationType === 1}
                                value={1}
                                disabled
                            />
                            Admin Reservation
                        </label>
                        <p className='text-light-gray'>
                            Your guests will only be able to send requests for
                            participation in this service. All seat assignments
                            will be handled by an admin
                        </p>
                    </div>
                    <div className='form-section'>
                        <label className='checkbox'>
                            <input
                                name='reservationType'
                                type='radio'
                                className='checkbox'
                                onChange={() =>
                                    formik.setFieldValue('reservationType', 2)
                                }
                                checked={formik.values.reservationType === 2}
                                value={2}
                                disabled
                            />
                            Admin Verified Seating
                        </label>
                        <p className='text-light-gray'>
                            Your guests will be able to select their seats, but
                            all requests will have to be verified by an admin.
                            Any conflicts will have to be solved by an admin, as
                            well.
                        </p>
                    </div>
                    <div className='form-section'>
                        <label className='checkbox'>
                            <input
                                name='reservationType'
                                type='radio'
                                className='checkbox'
                                onChange={() =>
                                    formik.setFieldValue('reservationType', 3)
                                }
                                checked={formik.values.reservationType === 3}
                                value={3}
                                disabled
                            />
                            Guest Reservation
                        </label>
                        <p className='text-light-gray'>
                            Your guests will be able to reserve their seats in a
                            first-come, first-served manner.{' '}
                        </p>
                    </div>
                    {formik.touched.reservationType &&
                    formik.errors.reservationType ? (
                        <div className='field-error'>
                            {formik.errors.reservationType}
                        </div>
                    ) : null}
                </section>
                <section>
                    <h5 className='title'>Social Distancing</h5>
                    <div className='form-section'>
                        <label htmlFor='name'>
                            {formik.values.reservationType === 4
                                ? 'Number '
                                : 'Percent '}
                            of Available Seats
                        </label>
                        <input
                            id='capacity'
                            name='capacity'
                            type='number'
                            className='form-control'
                            onChange={formik.handleChange}
                            value={formik.values.capacity}
                        />
                        {formik.touched.capacity && formik.errors.capacity ? (
                            <div className='field-error'>
                                {formik.errors.capacity}
                            </div>
                        ) : null}
                    </div>
                    {!noMapRes && autoDistance && (
                        <div className='form-section'>
                            <label htmlFor='name'>
                                Seats Between Reservations
                            </label>
                            <select
                                id='seats'
                                name='seats'
                                type='string'
                                className='form-control'
                                onChange={formik.handleChange}
                                value={`${formik.values.seats}`}
                            >
                                <option value={0}>No seats</option>
                                <option value={1}>1 seat</option>
                                <option value={2}>2 seats</option>
                                <option value={3}>3 seats</option>
                                <option value={4}>4 seats</option>
                            </select>
                        </div>
                    )}
                </section>
                <section>
                    <button
                        type='submit'
                        className='btn primary'
                        disabled={!(formik.isValid && formik.dirty)}
                    >
                        <Load loading={loading}>Submit</Load>
                    </button>
                    <button
                        type='button'
                        className='btn outline'
                        onClick={() => complete({})}
                    >
                        Cancel
                    </button>
                </section>
                {error ? (
                    <div className='alert-danger published'>
                        <strong>Oh no!</strong> {error}
                    </div>
                ) : null}
            </form>
        </div>
    );
}
