import React, { useContext, useState, useEffect } from 'react';
import { ServiceContext } from './Service';
import ReactPaginate from 'react-paginate';
import exportToCSV from '../../components/exportToCSV';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCheck } from '@fortawesome/free-solid-svg-icons';
import { writeDoc } from '../../base';

export default function ServiceReservations({ reservations, tabLists }) {
    const pageLimit = 10;
    const [filteredRes, setFilteredRes] = useState(false);
    const [pageList, setPageList] = useState([]);
    const [pageNum, setPageNum] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [empty, setEmpty] = useState(false);
    const context = useContext(ServiceContext);
    const [thinking, setThinking] = useState(false);

    useEffect(() => {
        if (context.reservations) {
            const validResList = context.reservations.filter(
                (res) => res.guest !== undefined
            );
            setPageCount(Math.ceil(validResList.length / pageLimit));

            if (validResList.length === 0) {
                return setEmpty(true);
            }

            if (validResList[0].createdDate) {
                setFilteredRes(
                    validResList.sort((a, b) => b.createdDate - a.createdDate)
                );
            } else {
                setFilteredRes(validResList.sort());
            }
        }
    }, [context.reservations]);

    useEffect(() => {
        if (filteredRes.length > 0) {
            const start = pageNum * pageLimit;
            const newData = filteredRes.slice(start, start + pageLimit);
            setPageList(newData);
        }
    }, [filteredRes, pageNum]);

    const exportList = () => {
        setThinking(true);
        exportToCSV(
            filteredRes,
            context.service.name,
            (context.service.map && context.service.map.includeSection) || false
        );
        setThinking(false);
    };

    const changePage = (data) => {
        setPageNum(data.selected);
    };

    const checkInGuest = (userId, bool) => {
        writeDoc(
            'reservations',
            userId,
            {
                checkedIn: !bool,
                checkedInAt: new Date().getTime()
            },
            true
        );
    };

    const filterSearch = (e) => {
        const target = e.target.value.toLowerCase();
        if (target === '') {
            setFilteredRes(
                context.reservations.filter((res) => res.guest !== undefined)
            );
        } else {
            setFilteredRes(
                context.reservations.filter(
                    (res) =>
                        res.guest &&
                        res.guest.name.toLowerCase().includes(target)
                )
            );
        }
    };

    if (empty) {
        return (
            <h5 className='text-light-gray'>
                <strong>Your guest list is empty</strong>
            </h5>
        );
    }

    return (
        <div className='card-list'>
            <div className='filters'>
                <div className='icon-input'>
                    <div className='input-icon'>
                        <FontAwesomeIcon icon={faSearch} />
                    </div>
                    <input
                        type='text'
                        className='form-control'
                        id='search'
                        onChange={filterSearch}
                        placeholder='Search this guest list'
                    />
                </div>
                <div className='export'>
                    <button
                        className='btn outline primary'
                        onClick={exportList}
                        disabled={thinking}
                    >
                        Export To CSV
                    </button>
                </div>
            </div>
            <div className='headers'>
                <div>Name</div>
                <div>Reserved Seats</div>
                <div>Email</div>
                <div>Phone</div>
                <div>&nbsp;</div>
            </div>
            <div className='items'>
                {pageList.map((res) => (
                    <div className='item-card card padding-small' key={res.id}>
                        <div className='name'>{res.guest.name}</div>
                        <div className='seats'>
                            {res.seats && res.seats.length
                                ? res.seats.map(
                                      (seat, i) =>
                                          `${
                                              context.service.map.includeSection
                                                  ? seat.view.section + '-'
                                                  : ''
                                          }${seat.view.row}-${
                                              seat.view.number
                                          }${
                                              res.seats.length > i + 1
                                                  ? ', '
                                                  : ''
                                          }`
                                  )
                                : res.requestedSeats}
                        </div>
                        <div className='email'>{res.guest.email}</div>
                        <div className='phone'>{res.guest.phone}</div>
                        <div className='extras'>
                            {res.firstTime && (
                                <div className='badge accent'>New</div>
                            )}
                            <button
                                className={`btn ${
                                    res.checkedIn ? 'success outline' : 'dark'
                                }`}
                                onClick={(e) =>
                                    checkInGuest(res.id, !!res.checkedIn)
                                }
                            >
                                {res.checkedIn ? (
                                    <FontAwesomeIcon icon={faCheck} />
                                ) : (
                                    'Check In'
                                )}
                            </button>
                        </div>
                    </div>
                ))}
                <ReactPaginate
                    previousLabel='Previous'
                    nextLabel='Next'
                    breakLabel='...'
                    breakClassName='break-me'
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(data) => changePage(data)}
                    containerClassName='paginate'
                    nextLinkClassName='btn dark'
                    previousLinkClassName='btn dark'
                    pageLinkClassName='btn outline primary'
                    activeLinkClassName='active'
                />
            </div>
        </div>
    );
}
