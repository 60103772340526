import React, { useState, useEffect, createContext, useContext } from 'react';
import SubNav from '../../components/subNav';
import Settings from './ServiceSettings';
import ServiceHeader from '../../components/serviceHeader';
import ServiceReservations from './ServiceReservations';
import ServiceEditSeating from './ServiceEditSeating';
import ServiceGuestList from './ServiceGuestList';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import useDocumentSnapshot from '../../hooks/useDocumentSnapshot';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import app, { writeDoc } from '../../base';
import { confirmAlert } from 'react-confirm-alert';
import { AuthContext } from '../../hooks/AuthContext';

// initialize the context
export const ServiceContext = createContext({
    reservations: [],
    service: {},
    details: [],
    id: '',
    needsNotifs: false,
    map: {}
});

export default function Service() {
    const [reservations, setReservations] = useState(false);
    const [service, setService] = useState(false);
    const [details, setDetails] = useState(false);
    const [map, setMap] = useState(false);
    const auth = useContext(AuthContext);
    const [needsNotifs, setNeedsNotifs] = useState(false);
    const [seats, setSeats] = useState(false);

    const navItems = [
        { name: 'Reservations', route: 'reservations' },
        { name: 'Edit Seating', route: 'edit-seating' },
        { name: 'Guest List', route: 'guest-list' },
        { name: 'Settings', route: 'settings' }
    ];
    const match = useRouteMatch();

    const sendNotifs = async () => {
        console.log('sending notifs');
        const needsUpdate = reservations.filter(
            (item) => item.needsUpdateNotif === true
        );

        try {
            await window.fetch(
                `${process.env.REACT_APP_FUNCTIONS_URL}/sendChangeEmail`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        reservations: needsUpdate,
                        service: service,
                        church: { name: auth.account.name }
                    })
                }
            );

            const batch = app.firestore().batch();
            for (const item of needsUpdate) {
                const doc = app
                    .firestore()
                    .collection('reservations')
                    .doc(item.id);
                batch.update(doc, { needsUpdateNotif: false });
            }
            await batch.commit();
            setNeedsNotifs(false);
        } catch (err) {
            console.log(err);
        }
    };

    const publishService = (status) => {
        confirmAlert({
            title: `Make this service ${
                status === 'draft' ? 'Private' : 'Public'
            }`,
            message:
                status === 'published'
                    ? 'Doing this will add this service on your landing page.'
                    : 'Doing this will remove this service from your landing page, but reservations can still be made for it.',
            buttons: [
                { label: 'Cancel' },
                {
                    label: 'Confirm',
                    onClick: () => {
                        const updatedServ = {
                            ...service,
                            status: status
                        };
                        writeDoc('services', service.id, updatedServ);
                    }
                }
            ],
            closeOnClickOutside: true
        });
    };

    const firebaseService = useDocumentSnapshot('services', match.params.id);
    const [
        snapshot,
        loading,
        error
    ] = useCollectionData(
        app
            .firestore()
            .collection('reservations')
            .where('serviceId', '==', match.params.id),
        { idField: 'id' }
    );

    useEffect(() => {
        if (firebaseService) {
            setService(firebaseService);
            setMap(firebaseService && firebaseService.map);
        }
    }, [firebaseService]);

    useEffect(() => {
        if (!loading && !error && snapshot) {
            snapshot.sort((a, b) => {
                if (!a.createdDate) {
                    return a;
                }
                return b.createdDate - a.createdDate;
            });
            console.log(snapshot);

            setReservations(snapshot);
            // used for the tab lists and the service header
            const tempDetails = !loading && {
                completed: snapshot.filter((res) => res.status === 'reserved'),
                requests: snapshot.filter((res) => res.status === 'requested')
            };
            setDetails(tempDetails);
        }
    }, [snapshot, firebaseService, error, loading]);
    return (
        <ServiceContext.Provider
            value={{
                reservations,
                service,
                details,
                id: service.id,
                needsNotifs,
                map,
                setNotifs: (bool) => setNeedsNotifs(bool),
                setSeats
            }}
        >
            <SubNav navItems={navItems} />
            <ServiceHeader
                sendNotifs={sendNotifs}
                changeStatus={publishService}
                seats={seats}
            />
            <div className='page-container service' id='service-page-top'>
                <Switch>
                    <Route exact path={`${match.url}/`}>
                        {service.reservationType === 4 && (
                            <Redirect to={`${match.url}/guest-list`} />
                        )}
                        {service.reservationType < 4 && (
                            <Redirect to={`${match.url}/reservations`} />
                        )}
                    </Route>
                    <Route path={`${match.url}/reservations`}>
                        {!loading && (
                            <ServiceReservations
                                reservations={reservations}
                                tabLists={details}
                            />
                        )}
                    </Route>
                    <Route path={`${match.url}/edit-seating`}>
                        {!loading && <ServiceEditSeating />}
                    </Route>
                    <Route path={`${match.url}/settings`}>
                        {/* More explicit checking because the form won't load correctly if service isn't fully set */}
                        {service.name && (
                            <Settings serviceObj={service} newService={false} />
                        )}
                    </Route>
                    <Route path={`${match.url}/guest-list`}>
                        {!loading && <ServiceGuestList />}
                    </Route>
                </Switch>
            </div>
        </ServiceContext.Provider>
    );
}
