import { useState, useEffect } from 'react';
import app from '../base';

const useDocumentSnapshot = (collectionName, docId) => {
    const [record, setRecord] = useState({});

    useEffect(() => {
        if (docId == null) {
            return function () {};
        }

        const unsubscribe = app
            .firestore()
            .collection(collectionName)
            .doc(docId)
            .onSnapshot((snapshot) => {
                const newRecord = {
                    id: snapshot.id,
                    ...snapshot.data()
                };
                setRecord(newRecord);
            });

        return unsubscribe;
    }, [collectionName, docId]);
    return record;
};

export default useDocumentSnapshot;
