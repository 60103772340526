import React, { useState, useEffect } from 'react';
import logo from '../img/centered-logo-white.png';

const SplashScreen = ({showLoad, children}) => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        setShow(showLoad);
    }, [showLoad]);
   
    return (
        <>
            <div className={`loading-screen ${show === false ? 'hide': ''}`}>
                <img src={logo} alt="" />
            </div>
            {show === false && children}
        </>
    )
}

export default SplashScreen;