import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../hooks/AuthContext';
import { writeDoc } from '../../base';
import Load from '../../components/interiorLoader';

const Emailing = () => {
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [emailingObj] = useState(
        auth.account.emailing || { customField1: '', sendFromSupport: false }
    );

    useEffect(() => {
        setTimeout(() => {
            setSuccess(false);
        }, 5000);
    }, [success]);

    const updateEmailing = async (values) => {
        setLoading(true);
        console.log();
        try {
            await writeDoc(
                'churches',
                auth.account.id,
                {
                    email: values.supportEmail,
                    emailing: {
                        customField1: values.customField1,
                        sendFromSupport: values.sendFromSupport
                    }
                },
                true
            );
            setSuccess(true);
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };

    return (
        <div className='page-container'>
            <h3 className='text-main'>Customize your Emails</h3>
            <div className='details grid w-50'>
                <h5 className='sub-title'>
                    You can customize your email notifications with the fields
                    below. You can leave them blank until you are ready to use
                    them without a problem.
                </h5>
                <div className='card'>
                    <Formik
                        initialValues={{
                            supportEmail: auth.account.email,
                            ...emailingObj
                        }}
                        onSubmit={updateEmailing}
                    >
                        <Form className='form'>
                            <div className='form-section'>
                                <label htmlFor='supportEmail'>
                                    Support Email
                                </label>
                                <Field
                                    name='supportEmail'
                                    type='email'
                                    className='form-control'
                                />
                            </div>
                            <div className='form-section'>
                                <Field name='sendFromSupport' type='checkbox' />
                                <label htmlFor='sendFromSupport'>
                                    &nbsp;Send notifications from my support
                                    email
                                </label>
                            </div>
                            <div className='form-section'>
                                <label htmlFor='customField1'>
                                    Custom Message
                                </label>
                                <Field
                                    name='customField1'
                                    type='text'
                                    className='form-control'
                                    as='textarea'
                                    rows='4'
                                />
                            </div>
                            <div className='form-section'>
                                <button className='btn dark' type='submit'>
                                    <Load loading={loading}>Update</Load>
                                </button>
                            </div>
                            {success && (
                                <div className='alert-success'>
                                    The support email has been updated.
                                </div>
                            )}
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default Emailing;
