import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import app, { writeDoc } from '../../../base';
import Load from '../../../components/interiorLoader';

const CreateUserForm = ({
    creatingAdmin,
    churchId,
    finalizeCreation,
    goBack
}) => {
    const [emailError, setEmailError] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const google = new app.firebase_.auth.GoogleAuthProvider();

    const emailCreate = async (values) => {
        try {
            await app.firebase_
                .auth()
                .createUserWithEmailAndPassword(
                    values.email.toLowerCase().trim(),
                    values.password.trim()
                );
            const currentUser = await app.firebase_.auth().currentUser;
            await currentUser.updateProfile({ displayName: values.name });
            return { user: currentUser };
        } catch (e) {
            console.log(e);
            if (e.code === 'auth/email-already-in-use') {
                try {
                    return await app.firebase_
                        .auth()
                        .signInWithEmailAndPassword(
                            values.email.toLowerCase().trim(),
                            values.password.trim()
                        );
                } catch (err) {
                    setEmailError(err.message);
                }
            } else {
                setLoading(false);
                setEmailError(e.message);
                throw Error(false);
            }
        }
    };

    const updateChurch = async (adminEmail) => {
        return await writeDoc(
            'churches',
            churchId,
            {
                admins: app.firebase_.firestore.FieldValue.arrayUnion(
                    adminEmail.toLowerCase().trim()
                )
            },
            true
        );
    };

    const createUser = async (authInfo) => {
        const user = await app
            .firestore()
            .collection('users')
            .where('email', '==', authInfo.email.toLowerCase().trim())
            .get();
        console.log(`Found ${user.docs.length} user match`);

        if (user.empty) {
            return await writeDoc(
                'users',
                null,
                {
                    name: authInfo.displayName || '',
                    email: authInfo.email.toLowerCase().trim(),
                    photoUrl: authInfo.photoURL || '',
                    admin: churchId,
                    uid: authInfo.uid,
                    [churchId]: true,
                    createdDate: new Date().toUTCString()
                },
                false
            );
        } else {
            return await writeDoc(
                'users',
                user.docs[0].id,
                {
                    name: authInfo.displayName,
                    photoUrl: authInfo.photoURL || '',
                    [churchId]: true,
                    uid: authInfo.uid,
                    lastUpdated: new Date().toUTCString(),
                    admin: churchId
                },
                true
            );
        }
    };

    const setupAdmin = async (form) => {
        try {
            let fbAuth;
            if (form === false) {
                fbAuth = await app.firebase_.auth().signInWithPopup(google);
            } else {
                fbAuth = await emailCreate(form);
            }
            console.log('auth email', fbAuth.user.email);

            const churchDoc = await app
                .firestore()
                .collection('churches')
                .doc(churchId)
                .get();
            console.log(churchDoc.data());

            const matching = churchDoc
                .data()
                .admins.find((admin) => admin === fbAuth.user.email);
            if (matching === undefined) {
                setError(
                    "You are not setup as an admin for a Church. Are you sure you're using the correct email?"
                );
                setLoading(false);
                return;
            }

            console.log('valid match');

            await createUser(fbAuth.user);
            finalizeCreation(fbAuth.user);
        } catch (error) {
            if (error.code === 'auth/popup-closed-by-user') {
                return setLoading(false);
            }
            console.log(error);
            setError('There has been an error. Please refresh and try again');
            // writeDoc('errors', new Date().getTime(), {err, message: err.message}, false);
            setLoading(false);
        }
    };

    const handleCreate = async (form) => {
        setLoading(true);
        setError(false);
        setEmailError(null);

        console.log(churchId);
        console.log(creatingAdmin);

        if (creatingAdmin === true) {
            return setupAdmin(form);
        }

        let fbAuth;
        // *** The goal is to update the church as early as possible to avoid any weirdness
        try {
            if (form === false) {
                fbAuth = await app.firebase_.auth().signInWithPopup(google);
                await updateChurch(fbAuth.user.email);
            } else {
                await updateChurch(form.email);
                fbAuth = await emailCreate(form);
                // console.log(fbAuth);
                // console.log(fbAuth.user);
            }

            await createUser(fbAuth.user);
            finalizeCreation(fbAuth.user);
        } catch (error) {
            if (error.code === 'auth/popup-closed-by-user') {
                return setLoading(false);
            }
            setError(error.message);
            console.log(error);
            setLoading(false);
        }
    };

    return (
        <Formik
            initialValues={{ name: '', email: '', password: '' }}
            validationSchema={Yup.object({
                name: Yup.string().required('Name is required'),
                email: Yup.string()
                    .email('Please enter a valid email address')
                    .required('Please enter an email address'),
                password: Yup.string().required('Please enter an password')
            })}
            onSubmit={(values) => {
                handleCreate(values);
            }}
        >
            <Form className='form'>
                <div className='header'>
                    {creatingAdmin === false && (
                        <div className='goBack' onClick={goBack}>
                            <FontAwesomeIcon
                                icon={faArrowCircleLeft}
                                size='2x'
                            />
                        </div>
                    )}
                    <h4>Create Your Personal Account</h4>
                </div>
                {error !== false && (
                    <div className='alert-danger'>
                        <strong>Oh no!</strong> {error}
                    </div>
                )}
                <div className='form-section'>
                    <div className='grid-wrapper provider-signin'>
                        <button
                            className='btn outline withIcon'
                            onClick={() => handleCreate(false)}
                            type='button'
                        >
                            <Load loading={loading}>
                                <img
                                    className='provider-icon'
                                    src='https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg'
                                    alt='google logo'
                                />
                                Google
                            </Load>
                        </button>
                    </div>
                </div>
                <div className='form-section login-break'>
                    <div className='line' />
                    <div className='or'>or</div>
                </div>
                <div className='form-section'>
                    <label htmlFor='name'>Name</label>
                    <Field name='name' type='string' className='form-control' />
                    <ErrorMessage
                        name='name'
                        render={(msg) => (
                            <div className='field-error'>{msg}</div>
                        )}
                    />
                </div>
                <div className='form-section'>
                    <label htmlFor='email'>Email Address</label>
                    <Field name='email' type='email' className='form-control' />
                    <ErrorMessage
                        name='email'
                        render={(msg) => (
                            <div className='field-error'>{msg}</div>
                        )}
                    />
                </div>
                <div className='form-section'>
                    <label htmlFor='password'>Password</label>
                    <Field
                        name='password'
                        type='password'
                        className='form-control'
                    />
                    <ErrorMessage
                        name='password'
                        render={(msg) => (
                            <div className='field-error'>{msg}</div>
                        )}
                    />
                    {emailError && (
                        <div className='field-error'>{emailError}</div>
                    )}
                </div>
                <button type='submit' className='btn accent'>
                    <Load loading={loading}>Start Using ChurchReserve</Load>
                </button>
            </Form>
        </Formik>
    );
};

export default CreateUserForm;
