import React, { useState, useContext, useEffect } from 'react';
import useCollectionSnapshot from '../../hooks/useCollectionSnapshot';
import Modal from '../../hooks/Modal';
import { useFormik } from 'formik';
import app, { writeDoc } from '../../base';
import { AuthContext } from '../../hooks/AuthContext';

export default function ChurchAdmins({ standalonePage }) {
    const [showModal, setShowModal] = useState(false);
    const [formError, setFormError] = useState(false);
    const [adminUsers, setAdminUsers] = useState([]);
    const auth = useContext(AuthContext);

    const admins = useCollectionSnapshot('users', {
        field: 'admin',
        value: auth.account.id
    });

    useEffect(() => {
        if (admins) {
            const withoutSupes = auth.account.admins.filter(
                (email) => !email.includes('@churchreserve.com')
            );
            setAdminUsers(
                withoutSupes.map((email) => {
                    const f = admins.find((obj) => obj.email === email);
                    if (f) {
                        return {
                            name: f.name,
                            email: f.email,
                            id: f.id,
                            status: 'Completed'
                        };
                    } else {
                        return {
                            name: '--',
                            email: email,
                            status: 'Invited'
                        };
                    }
                })
            );
        }
    }, [admins, auth.account.admins]);

    const submitUser = async (newAdmin) => {
        const adminObj = {
            ...newAdmin,
            email: newAdmin.email.toLowerCase().trim()
        };

        try {
            await sendEmail(auth.account.name, adminObj, auth.account.id);
            await writeDoc('churches', auth.account.id, {
                admins: app.firebase_.firestore.FieldValue.arrayUnion(
                    adminObj.email
                )
            });
            setShowModal(false);
        } catch (error) {
            console.log(error);
            setFormError(true);
        }
    };

    const sendEmail = (accoutName, adminObj, accoutId) => {
        window.fetch(
            `${process.env.REACT_APP_FUNCTIONS_URL}/sendNewAdminEmail`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    churchName: accoutName,
                    newAdmin: adminObj,
                    accountId: accoutId
                })
            }
        );
    };

    const deleteAdmin = async (admin) => {
        try {
            await writeDoc('churches', auth.account.id, {
                admins: app.firebase_.firestore.FieldValue.arrayRemove(
                    admin.email
                )
            });
            await writeDoc('users', admin.id, {
                admin: ''
            });
        } catch (e) {
            console.log(e);
        }
    };

    const resendEmail = async (admin) => {
        await sendEmail(auth.account.name, admin, auth.account.id);
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            name: ''
        },
        validate: (values) => {
            const errors = {};
            if (!values.email) {
                errors.email = 'An Email is required';
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = 'Invalid email address';
            }

            if (!values.name) {
                errors.name = 'A name is required';
            }
            return errors;
        },
        onSubmit: (values) => {
            submitUser(values);
        }
    });

    const openModal = (newAdmin) => {
        // setUpdateAdmin(newAdmin);
        setShowModal(true);
    };

    return (
        <div className={`${standalonePage ? 'page-container' : ''}`}>
            {standalonePage && <h3 className='text-main'>Church Admins</h3>}
            <Modal isOpen={showModal} close={() => setShowModal(false)}>
                {formError && (
                    <div className='alert-danger'>
                        <strong>Oh no!</strong> There was a problem while
                        creating or updating this admin. Try again later.
                    </div>
                )}
                <h4>Create a new admin</h4>
                <form onSubmit={formik.handleSubmit} className='form'>
                    <div className='form-section'>
                        <label htmlFor='name'>Name</label>
                        <input
                            id='name'
                            name='name'
                            type='text'
                            className='form-control'
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                    </div>
                    <div className='form-section'>
                        <label htmlFor='name'>Email</label>
                        <input
                            id='email'
                            name='email'
                            type='email'
                            className='form-control'
                            onChange={formik.handleChange}
                            value={formik.values.email}
                        />
                    </div>
                    <button className='btn primary' type='submit'>
                        Save
                    </button>
                    <button
                        className='btn outline'
                        onClick={() => setShowModal(false)}
                    >
                        Cancel
                    </button>
                </form>
            </Modal>

            <div className='create'>
                <button className='btn outline' onClick={() => openModal({})}>
                    Create an Admin
                </button>
            </div>
            <br />
            <div className='card' style={{ overflow: 'auto' }}>
                <table className='table'>
                    <thead>
                        <tr>
                            <th className='hidden-mobile'>Name</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {adminUsers.map((guest) => (
                            <tr key={guest.email}>
                                <td className='hidden-mobile'>{guest.name}</td>
                                <td>{guest.email}</td>
                                <td>{guest.status}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    {guest.status.toLowerCase() ===
                                        'invited' && (
                                        <button
                                            className='btn primary'
                                            onClick={() => resendEmail(guest)}
                                        >
                                            Resend Email
                                        </button>
                                    )}
                                    <button
                                        className='btn outline'
                                        onClick={() => deleteAdmin(guest)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
