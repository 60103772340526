import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import app from '../base';

export default function SubNav({ navItems, showSignOut = false }) {
    const match = useRouteMatch();

    const signOut = () => {
        app.firebase_.auth().signOut();
        window.location.reload();
    };
    return (
        <div className='subNav' id='subnav'>
            {navItems.map((item, i) => (
                <NavLink
                    to={`${match.url}/${item.route}`}
                    key={i}
                    id={item.name}
                    activeClassName='topNav-active'
                >
                    <div className='subNav-title'>{item.name}</div>
                    <div className='subNav-bar' />
                </NavLink>
            ))}

            {showSignOut === true && (
                <div className='signOut'>
                    <button className='btn link' onClick={signOut}>
                        Sign Out
                    </button>
                </div>
            )}
        </div>
    );
}
