import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../hooks/AuthContext';
import Load from '../components/interiorLoader';

export default function SubscriptionCard({
    plan,
    discount = 0,
    selectSub,
    usePaid
}) {
    const [planPrice, setPlanPrice] = useState(0);
    const [discounted, setDiscounted] = useState(0);
    const [paidPrice, setPaidPrice] = useState({
        price: ['0', '00'],
        repeats: 'month'
    });
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (plan) {
            const remain = 1 - discount;
            setPlanPrice(
                (remain * plan.price).toFixed(2).toString().split('.')
            );
        }
        setDiscounted(discount > 0);
    }, [discount, plan]);

    useEffect(() => {
        if (auth.account && auth.account.subscription) {
            const sub = auth.account.subscription;
            const price = sub.amount / 100;
            const repeats = `${sub.timeframe > 1 ? sub.timeframe : ''} ${
                sub.timeframe > 1 ? sub.interval + 's' : sub.interval
            }`;

            setPaidPrice({
                price: price.toFixed(2).toString().split('.'),
                repeats,
                bracket: auth.account.subscription.bracket
            });
        }
    }, [auth.account]);

    const choosePrice = () => {
        setLoading(true);
        selectSub(discounted ? plan.yearly : plan.sixMonths);
    };

    if (!plan) {
        return <div />;
    }
    return (
        <div className='card subscription-card'>
            <div className='plan-title' style={{ textTransform: 'capitalize' }}>
                {(plan && plan.name) || paidPrice.bracket}
            </div>
            <div className='desc'>{plan && plan.shortDescription}</div>
            {!usePaid ? (
                <div className='price'>
                    <div className='line' />
                    <div className='number'>
                        <div>{planPrice[0]}</div>
                        <div className='digits'>.00</div>
                        <div className='month'>/ month</div>
                    </div>
                    {plan.bracket !== 'free' && (
                        <button
                            className='btn dark outline'
                            onClick={choosePrice}
                        >
                            <Load loading={loading}>Get Started</Load>
                        </button>
                    )}
                    <div className='line' />
                </div>
            ) : (
                <div className='price'>
                    <div className='line' />
                    <div className='number'>
                        <div>{paidPrice.price[0]}</div>
                        <div className='digits'>
                            .{paidPrice.price[1] || '00'}
                        </div>
                        <div className='month'>/ {paidPrice.repeats}</div>
                    </div>
                </div>
            )}
            {!usePaid && (
                <div className='perks'>
                    <ul>
                        <li>
                            {plan.limitType} {plan.attendanceLimit} weekly
                            attendance
                        </li>

                        {plan.bracket !== 'free' && (
                            <li>
                                <div>Up to {plan.mapComplex} seating map</div>
                            </li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
}
