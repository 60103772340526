import React, { useContext } from 'react';
import {
    ShrinkSidebarContext,
    ShowSidebarContext
} from '../../hooks/SidebarContext';
import SidebarNavLink from '../../components/sidebarNavLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import centerLogoWhite from '../../img/centered-logo-white.png';
import icon from '../../img/icon.jpg';
import { AuthContext } from '../../hooks/AuthContext';

export default function Sidebar({ children }) {
    const [showMenu, setShowMenu] = useContext(ShowSidebarContext);
    const [shrinkMenu, setShrinkMenu] = useContext(ShrinkSidebarContext);
    const auth = useContext(AuthContext);

    return (
        <div
            className={`churchreserve ${showMenu ? 'menu-open' : ''} ${
                shrinkMenu ? 'menu-shrunk' : ''
            }`}
        >
            <div className='sidebar'>
                <div className='logo'>
                    {shrinkMenu ? (
                        <img src={icon} alt='church reserve logo' />
                    ) : (
                        <img src={centerLogoWhite} alt='church reserve logo' />
                    )}
                </div>
                <div className='sidebar-container'>
                    <nav
                        onClick={() => {
                            setShowMenu(false);
                        }}
                    >
                        <div className='top nav-menu'>
                            <ul>
                                <SidebarNavLink
                                    to='/admin/services'
                                    icon='microphone'
                                    text='Services'
                                />
                                <SidebarNavLink
                                    to='/admin/create'
                                    icon='plus'
                                    text='Create a service'
                                />
                                {/* <SidebarNavLink
                                    to='/admin/guests'
                                    icon='friends'
                                    text='All Guests'
                                /> */}
                                <SidebarNavLink
                                    to='/admin/billing'
                                    icon='dollar'
                                    text='Billing'
                                />
                                <SidebarNavLink
                                    to='/admin/admins'
                                    icon='laptop'
                                    text='Admins'
                                />
                                <SidebarNavLink
                                    to='/admin/emails'
                                    icon='envelope'
                                    text='Emails'
                                />
                                <div className='divider' />
                                <SidebarNavLink
                                    to='/admin/church'
                                    icon='church'
                                    text='My Church'
                                />
                                <SidebarNavLink
                                    external='https://churchreserve.com/training/'
                                    icon='training'
                                    text='Video Training'
                                />
                                <SidebarNavLink
                                    external='https://churchreserve.com/contact-churchreserve/'
                                    icon='question'
                                    text='Support'
                                />
                            </ul>
                        </div>
                    </nav>
                    <div
                        className='nav-toggle'
                        onClick={(e) => {
                            e.preventDefault();
                            setShrinkMenu(!shrinkMenu);
                        }}
                    >
                        <div className='icon'>
                            <FontAwesomeIcon
                                icon={faAngleDoubleLeft}
                                size='lg'
                            />
                            <div>Close Sidebar</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main'>
                <header>
                    <div
                        className='hamburger'
                        onClick={(e) => {
                            e.preventDefault();
                            setShowMenu(!showMenu);
                        }}
                    >
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className='logo-text' />
                </header>
                {children}
            </div>
        </div>
    );
}
