import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const Load = ({ children, loading }) => {
    const [showLoad, setShowLoad] = useState(false);

    useEffect(() => {
        setShowLoad(loading);
    }, [loading]);

    return (
        <div className={`interior-loader ${showLoad ? 'loading' : ''}`}>
            <div className='spinner'>
                <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
            <div className='content'>{children}</div>
        </div>
    );
};

export default Load;
