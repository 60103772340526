import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default function ReservationsList({
    reservations,
    resSelected,
    type,
    updateRes,
    selectedSeats,
    showSection
}) {
    const [waiting, setWaiting] = useState(true);
    const [activeSelect, setActiveSelect] = useState(null);
    const [editMode, setEditMode] = useState(null);
    const [filteredRes, setFilteredRes] = useState([]);

    setTimeout(() => {
        setWaiting(false);
    }, 750);

    const filterSearch = (e) => {
        const target = e.target.value;
        if (target === '') {
            setFilteredRes(reservations);
        } else {
            setFilteredRes(
                reservations.filter((res) =>
                    res.guest.name.toLowerCase().includes(target.toLowerCase())
                )
            );
        }
    };

    useEffect(() => {
        if (reservations) {
            setFilteredRes(reservations);
        }
    }, [reservations]);

    const toggleSelect = (index, seats) => {
        if (index === activeSelect) {
            setEditMode(null);
            setActiveSelect(null);
            resSelected([]);
        } else {
            setEditMode(index);
            setActiveSelect(index);
            resSelected(seats);
        }
    };

    const handleUpdate = (item, i) => {
        if (selectedSeats.length < 1) {
            confirmAlert({
                title: 'Empty Reservation',
                message: `Are you sure you would like to save ${item.guest.name}'s reservation without any seats?`,
                buttons: [
                    { label: "No I wouldn't" },
                    {
                        label: 'Yes I would',
                        onClick: () => {
                            updateRes(item, { status: 'reserved' });
                            toggleSelect(i);
                        }
                    }
                ],
                closeOnClickOutside: true
            });
        } else {
            updateRes(item, { status: 'reserved' }, type);
            toggleSelect(i);
        }
    };

    const handleDelete = (item, i) => {
        setActiveSelect(i);
        resSelected(item.seats);
        confirmAlert({
            title: 'Confirm deletion',
            message: `Are you sure you want to delete ${item.guest.name}'s reservation?`,
            buttons: [
                {
                    label: 'Cancel',
                    onClick: () => {
                        setActiveSelect(null);
                        resSelected([]);
                    }
                },
                {
                    label: 'Confirm',
                    onClick: () => {
                        updateRes(item, { delete: true });
                    }
                }
            ],
            closeOnClickOutside: true
        });
    };

    return (
        <>
            <div className='margin-bottom icon-input'>
                <div className='input-icon'>
                    <FontAwesomeIcon icon={faSearch} />
                </div>
                <input
                    type='text'
                    className='form-control'
                    id='search'
                    onChange={filterSearch}
                    placeholder='Search Reservations'
                />
            </div>
            <div className='reservations-list'>
                {waiting === false && filteredRes.length === 0 && (
                    <h5>There are no reservations to show.</h5>
                )}
                {waiting === false &&
                    filteredRes.map((item, i) => (
                        <div
                            className={`reservation ${
                                activeSelect === i ? 'active' : ''
                            }`}
                            key={`${item.level}${item.row}${i}`}
                        >
                            <div
                                className='top'
                                onClick={() => toggleSelect(i, item.seats)}
                            >
                                <div className='guest'>
                                    <div className='name'>
                                        {item.guest.name}
                                    </div>
                                    <div className='seats'>
                                        {item.seats.map((seat) => (
                                            <div
                                                key={`${
                                                    showSection
                                                        ? seat.view.section
                                                        : ''
                                                }${seat.view.row}${
                                                    seat.view.number
                                                }`}
                                            >
                                                {`${
                                                    showSection
                                                        ? seat.view.section +
                                                          '-'
                                                        : ''
                                                }${seat.view.row}-${
                                                    seat.view.number
                                                }`}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='count'>
                                    {item.requestedSeats &&
                                    item.requestedSeats > 0 &&
                                    type === 'requests'
                                        ? item.requestedSeats
                                        : item.seats.length}
                                </div>
                            </div>

                            {editMode === i ? (
                                <div className='btns editing'>
                                    <div
                                        className='confirmation'
                                        onClick={() => handleUpdate(item, i)}
                                    >
                                        {type === 'requests'
                                            ? 'Confirm'
                                            : 'Save'}
                                    </div>
                                    <div
                                        className='text-danger'
                                        onClick={() => toggleSelect(i)}
                                    >
                                        Cancel
                                    </div>
                                </div>
                            ) : (
                                <div className='btns confirm'>
                                    <div
                                        onClick={() =>
                                            toggleSelect(i, item.seats)
                                        }
                                    >
                                        {type === 'requests'
                                            ? 'Approve'
                                            : 'Edit'}
                                    </div>
                                    <div
                                        className='text-alert'
                                        onClick={() => handleDelete(item, i)}
                                    >
                                        Delete
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
            </div>
        </>
    );
}
