import React, { useState } from 'react';
import { TabContext } from '../hooks/TabContext';

export default function ReservationNav({ children }) {
    const [tab, setTab] = useState(1);

    return (
        <TabContext.Provider value={tab}>
            <div className='reservationNav'>
                <div
                    onClick={() => setTab(1)}
                    className={`${tab === 1 ? 'active' : null}`}
                >
                    Requests
                </div>
                <div
                    onClick={() => setTab(3)}
                    className={`${tab === 3 ? 'active' : null}`}
                >
                    Complete
                </div>
                {/* <div
                    onClick={() => setTab(2)}
                    className={`${tab === 2 ? 'active' : null}`}
                >
                    Conflicts
                </div> */}
            </div>
            {children}
        </TabContext.Provider>
    );
}
