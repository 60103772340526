import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../hooks/Modal';
import { AuthContext } from '../../hooks/AuthContext';
import { ServiceContext } from './Service';
import { deleteDoc } from '../../base';
import { useHistory } from 'react-router-dom';
import UpdateServiceForm from '../../forms/updateServiceForm';

export default function Settings() {
    const context = useContext(ServiceContext);
    const auth = useContext(AuthContext);
    const history = useHistory();
    const [status, setStatus] = useState(false);
    const [deleteFailedOpen, setDeleteFailedOpen] = useState(false);
    const [deleteWarnOpen, setDeleteWarnOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [disableDelete, setDisableDelete] = useState(false);

    useEffect(() => {
        if (context.reservations) {
            if (
                context.reservations.filter((res) => res.status !== 'blockout')
                    .length > 0
            ) {
                setDisableDelete(true);
            }
        }
    }, [context.reservations]);

    const copyToClipboard = (e) => {
        const link = e.target;
        link.select();
        document.execCommand('copy');
    };

    const deleteService = () => {
        if (context.reservations) {
            if (
                context.reservations.filter((res) => res.status !== 'blockout')
                    .length > 0
            ) {
                setDeleteFailedOpen(true);
            } else {
                setDeleteWarnOpen(true);
            }
        } else {
            setDeleteWarnOpen(true);
        }
    };

    const getResTypeName = (num) => {
        switch (num) {
            case 1:
                return 'Admin Seating';
            case 2:
                return 'Verified Seating';
            case 3:
                return 'Guest Selection Seating';
            case 4:
                return 'Invitations Only';
            default:
                return 'Unknown Type';
        }
    };

    const editService = () => {
        setEditOpen(true);
        setStatus(false);
        document.body.style.overflow = 'hidden';
    };

    const updateStatus = (statusArg) => {
        if (statusArg.success === true) {
            setStatus({
                title: 'Success',
                class: 'alert-success',
                msg: ' Your service has been updated.'
            });
        } else if (statusArg.success === false) {
            setStatus({
                title: 'Oh No!',
                class: 'alert-danger',
                msg: ' Your sevice could not be updated.'
            });
        }
        setEditOpen(false);
        document.body.style.overflow = 'auto';
    };

    return (
        <>
            {context.service && (
                <div className='serviceSettings'>
                    <div className='details grid w-50'>
                        {status ? (
                            <div className={`${status.class} published`}>
                                <strong>{status.title}:</strong> {status.msg}
                            </div>
                        ) : null}
                        <div className='card margin-bottom'>
                            <section className='form-section service-link'>
                                <h5 className='first'>Link for your guests</h5>
                                <div className='input-icon'>
                                    <FontAwesomeIcon icon={faCopy} />
                                </div>
                                <input
                                    id='service-link'
                                    name='service-link'
                                    type='text'
                                    className='form-control clickable'
                                    value={`${auth.account.url}.churchreserve.com/${context.id}`}
                                    onClick={copyToClipboard}
                                    readOnly
                                />
                            </section>
                            <section>
                                <h5 className='title'>Details</h5>
                                <div className='item'>
                                    <div>Name: </div>
                                    {context.service.name}
                                </div>
                                <div className='item text-capitalize'>
                                    <div>Status: </div>
                                    {context.service.status === 'draft'
                                        ? 'Private'
                                        : 'Public'}
                                </div>
                                <div className='item'>
                                    <div>Date: </div>
                                    {context.service.dateStr.date}
                                </div>
                                <div className='item'>
                                    <div>Time: </div>
                                    {context.service.dateStr.time}
                                </div>
                                <div className='item'>
                                    <div>Reservation Type: </div>
                                    {getResTypeName(
                                        context.service.reservationType
                                    )}
                                </div>
                            </section>
                            <section>
                                <h5 className='title'>Social Distancing</h5>
                                <div className='item'>
                                    <div>Capacity: </div>
                                    {context.service.socialDistancing.capacity}
                                    {context.service.socialDistancing
                                        .capacityType === 'number'
                                        ? ' people'
                                        : '%'}
                                </div>
                                {context.service.reservationType === 3 && (
                                    <div className='item'>
                                        <div>Seats Between Reservations: </div>
                                        {
                                            context.service.socialDistancing
                                                .seats
                                        }{' '}
                                        seat
                                        {context.service.socialDistancing
                                            .seats === 1
                                            ? ''
                                            : 's'}
                                    </div>
                                )}
                            </section>
                            <br />
                            <button
                                className='btn primary'
                                onClick={editService}
                            >
                                Edit Service
                            </button>
                        </div>

                        <div className='card margin-bottom'>
                            <div className='form'>
                                <section>
                                    <h5 className='first'>Danger Zone</h5>
                                    <label>
                                        <button
                                            className='btn alert outline'
                                            onClick={deleteService}
                                            disabled={disableDelete}
                                        >
                                            Delete Service
                                        </button>
                                    </label>
                                    <div className='text-light-gray delete-msg'>
                                        This can only be done if a service has
                                        no reservations.
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`updateForm 
                        ${editOpen ? 'open' : ''}`}
                    >
                        {editOpen && (
                            <UpdateServiceForm
                                service={context.service}
                                complete={(status) => updateStatus(status)}
                            />
                        )}
                    </div>
                </div>
            )}
            <Modal
                isOpen={deleteWarnOpen}
                close={() => setDeleteWarnOpen(false)}
            >
                <div className='form'>
                    <h5>Confirm Deletion</h5>
                    <div className='form-section'>
                        Once a service is deleted it will be gone forever. Are
                        you sure you want to delete this service?
                    </div>

                    <button
                        className='btn alert'
                        onClick={() => {
                            deleteDoc('services', context.id);
                            document.body.style.overflow = 'initial';
                            history.push('/admin/services/scheduled');
                        }}
                    >
                        Yes, delete service
                    </button>
                    <button
                        className='btn outline'
                        onClick={() => setDeleteWarnOpen(false)}
                    >
                        Cancel
                    </button>
                </div>
            </Modal>
            <Modal
                isOpen={deleteFailedOpen}
                close={() => setDeleteFailedOpen(false)}
            >
                <div className='form'>
                    <h5>Delete Failed</h5>
                    <div className='form-section'>
                        You cannot delete a service where reservations have been
                        made.
                    </div>
                    <button
                        className='btn primary'
                        onClick={() => setDeleteFailedOpen(false)}
                    >
                        Ok
                    </button>
                </div>
            </Modal>
        </>
    );
}
